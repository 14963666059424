.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-content {
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 10px;
    max-width: 800px;
    width: 90%;
    max-height: 90vh;
    overflow-y: auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  .popup-content h2 {
    color: #301934;
    font-size: 1.8rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    text-align: center;
    border-bottom: 2px solid #fb8500;
    padding-bottom: 0.5rem;
  }
  
  .popup-body {
    display: flex;
    gap: 2rem;
  }
  
  .section-nav {
    flex: 1;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #e0e0e0;
    padding-right: 1rem;
  }
  
  .section-nav button {
    background: none;
    border: none;
    text-align: left;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    cursor: pointer;
    transition: all 0.3s ease;
    border-radius: 5px;
    font-weight: 500;
  }
  
  .section-nav button:hover {
    background-color: #f0f0f0;
    color: #fb8500;
  }
  
  .section-nav button.active {
    font-weight: 700;
    color: #ffffff;
    background-color: #fb8500;
  }
  
  .section-content {
    flex: 2;
  }
  
  .section-content h3 {
    color: #301934;
    font-size: 1.4rem;
    font-weight: 700;
    margin-bottom: 1rem;
    border-bottom: 1px solid #fb8500;
    padding-bottom: 0.5rem;
  }
  
  .section-content p, .section-content ul {
    color: #4a4a4a;
    line-height: 1.6;
    margin-bottom: 1rem;
  }
  
  .section-content ul {
    padding-left: 1.5rem;
  }
  
  .section-content li {
    margin-bottom: 0.5rem;
  }
  
  
  @media (max-width: 768px) {
    .popup-body {
      flex-direction: column;
    }
  
    .section-nav {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      border-right: none;
      border-bottom: 1px solid #e0e0e0;
      padding-bottom: 1rem;
      margin-bottom: 1rem;
    }
  
    .section-nav button {
      font-size: 0.9rem;
      margin: 0.25rem;
    }

  }