.sliding_banner_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #FB8500;
  color: white;
  z-index: 1000;
  overflow: hidden;
  height: 40px;
  font-family: 'Poppins', sans-serif;
}

.sliding_banner_content {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.sliding_banner_content::before,
.sliding_banner_content::after {
  content: '';
  position: absolute;
  top: 0;
  width: 50px;
  height: 100%;
  z-index: 2;
}

.sliding_banner_content::before {
  left: 0;
  background: linear-gradient(to right, #FB8500, transparent);
}

.sliding_banner_content::after {
  right: 0;
  background: linear-gradient(to left, #FB8500, transparent);
}

.sliding_banner_track {
  display: inline-flex;
  height: 100%;
  animation: banner-scroll 30s linear infinite;
  white-space: nowrap;
  align-items: center;
}

.sliding_banner_track span {
  display: inline-flex;
  align-items: center;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 500;
  color: white;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

@keyframes banner-scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

/* Hover durumunda animasyonu yavaşlat */
.sliding_banner_container:hover .sliding_banner_track {
  animation-play-state: paused;
}

/* Mobil için düzenlemeler */
@media (max-width: 768px) {
  .sliding_banner_track span {
    font-size: 12px;
    padding: 0 15px;
  }
  
  .sliding_banner_container {
    height: 36px;
  }
}

/* Navbar ile çakışmaması için sayfa içeriğine padding ekleyin */
.main-content {
  padding-top: 40px;
}