/* Genel stiller */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  background-color: transparent;
  position: relative;
}

/* Desktop Menu */
.desktop-menu {
  display: none;
  align-items: center;
  gap: 3rem;
}

.nav-link {
  color: #4b5563;
  margin: 0.25rem;
  font-weight: normal;
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #fb8500;
  text-decoration: underline;
}

.nav-link.active {
  color: #fb8500;
}

/* Courses dropdown styles */
.courses-dropdown {
  position: relative;
  display: inline-block;
}

.courses-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  min-width: 200px;
  z-index: 1000;
  padding: 0.5rem 0;
}

.dropdown-item {
  display: block;
  padding: 0.75rem 1rem;
  color: #4b5563;
  text-decoration: none;
  transition: all 0.3s ease;
}

.dropdown-item:hover {
  background-color: #f3f4f6;
  color: #fb8500;
}

.dropdown-item.active {
  color: #fb8500;
  background-color: #f3f4f6;
}

/* Primary button */
.primary-button {
  background-color: #fb8500;
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 1rem;
  width: 250px;
  height: 40px;
  border-radius: 0.75rem;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.primary-button:hover {
  background-color: #fa7b00;
}

/* Mobil navigasyon container */
.mobile-navigation-container {
  display: none;
}

@media (max-width: 1023px) {
  .mobile-navigation-container {
    display: block;
  }
}

/* Mobil alt menü */
.mobile-menu-bottom {
  font-family: 'Poppins', sans-serif;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 10px 0;
}

.mobile-menu-bottom ul {
  display: flex;
  justify-content: space-around;
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
}

.mobile-menu-bottom li {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  color: #4b5563;
  cursor: pointer;
  transition: color 0.3s ease;
}

.mobile-menu-bottom li span {
  margin-top: 4px;
}

.mobile-menu-bottom li.active,
.mobile-menu-bottom li:hover {
  color: #fb8500;
}

.mobile-menu-bottom li svg {
  font-size: 20px;
}

/* Mobil kurs dropdown */
.mobile-course-dropdown {
  position: fixed;
  bottom: -100%;
  left: 0;
  width: 100%;
  background-color: white;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  box-shadow: 0 -4px 6px -1px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  transition: bottom 0.3s ease-in-out;
}

.mobile-course-dropdown.show {
  bottom: 70px;
}

.mobile-dropdown-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #e5e7eb;
}

.mobile-dropdown-header span {
  font-weight: 600;
  color: #4b5563;
}

.mobile-dropdown-content {
  max-height: 60vh;
  overflow-y: auto;
}

.mobile-course-item {
  padding: 16px;
  border-bottom: 1px solid #e5e7eb;
  color: #4b5563;
  cursor: pointer;
  transition: background-color 0.2s ease;
  text-align: center;
}

.mobile-course-item:active {
  background-color: #f3f4f6;
}

.mobile-course-item.active {
  color: #fb8500;
  background-color: #fff7ed;
}

/* Overlay */
.mobile-dropdown-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 70px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

/* Masaüstü görünüm */
@media (min-width: 1024px) {
  .desktop-menu {
    display: flex;
  }
  
  .mobile-navigation-container {
    display: none;
  }
}