.pricing-container {
  background-color: white;
  padding: 4rem 2rem;
  font-family: 'Poppins', sans-serif;
}

.pricing-title {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 700;
  color: #301934;
  margin-bottom: 1rem;
}

.pricing-subtitle {
  text-align: center;
  font-size: 1.2rem;
  color: #4B5563;
  margin-bottom: 3rem;
}

.pricing-cards {
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
}

.pricing-card {
  background-color: #FFFFFF;
  border-radius: 20px;
  padding: 2rem;
  width: 300px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
  position: relative;
  border: 3px solid #EF4444;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.pricing-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1);
}

.pricing-card.popular {
  border: 3px solid #FB8500;
}

.pricing-card.best-value {
  border: 3px solid #10B981;
}

.popular-label, .best-value-label {
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-weight: 600;
  font-size: 0.9rem;
}

.popular-label {
  background-color: #FB8500;
  color: white;
}

.best-value-label {
  background-color: #10B981;
  color: white;
}

.card-title {
  font-size: 1.5rem;
  font-weight: 700;
  color: #301934;
  margin-bottom: 1rem;
}

.price-container {
  margin-bottom: 0.5rem;
}

.original-per-lesson-price {
  text-decoration: line-through;
  color: #4B5563;
  font-size: 1.2rem;
}

.discounted-per-lesson-price {
  font-size: 2.5rem;
  font-weight: 700;
  color: #FB8500;
  margin-top: 0.5rem;
}

.per-lesson {
  font-size: 1.2rem;
  color: #4B5563;
}

.total-price {
  font-size: 1rem;
  color: #4B5563;
  margin-bottom: 1rem;
}

.original-price {
  text-decoration: line-through;
  margin-right: 0.5rem;
}

.discounted-price {
  font-weight: 700;
  color: #10B981;
}

.discount-badge {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: #EF4444;
  color: white;
  padding: 0.25rem 0.5rem;
  border-radius: 20px;
  font-size: 0.8rem;
  font-weight: 600;
  transform: rotate(15deg);
}

.lessons-count {
  font-size: 1rem;
  color: #4B5563;
  margin-bottom: 1rem;
}

.features {
  list-style-type: none;
  padding: 0;
  margin-bottom: 2rem;
}

.features li {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  color: #4B5563;
}

.features li svg {
  margin-right: 0.5rem;
  color: #10B981;
}

.buy-button {
  width: 100%;
  padding: 0.75rem;
  background-color: #FB8500;
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.buy-button:hover {
  background-color: #F97316;
}

@media (max-width: 768px) {
  .pricing-cards {
    flex-direction: column;
    align-items: center;
  }

  .pricing-card {
    width: 100%;
    max-width: 350px;
  }
} 
