.student-management {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .student-management h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .class-selection {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .class-details,
  .student-list {
    margin-bottom: 40px;
  }
  
  .class-details input,
  .student-list input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 4px;
    border: 1px solid #ddd;
    box-sizing: border-box;
  }
  
  .student-list table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .student-list th,
  .student-list td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  .student-list th {
    background-color: #007bff;
    color: #fff;
  }
  
  .student-list td input {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
  }
  
  .btn-primary {
    padding: 10px 20px;
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .btn-primary:hover {
    background-color: #218838;
  }
  .add-student-form {
    margin-top: 20px;
    padding: 20px;
    background-color: #f1f1f1;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .add-student-form input {
    width: calc(100% - 22px);
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
    box-sizing: border-box;
  }
  
  .add-student-form .btn-primary {
    width: 100%;
  }
  
  