/* Sidebar Styles */
.ubiAI-sidebar {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 320px;
    background: var(--ubiAI-card-bg);
    border-right: 1px solid var(--ubiAI-border);
    transition: all 0.3s ease;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    box-shadow: 2px 0 8px rgba(0, 0, 0, 0.05);
  }
  
  .ubiAI-sidebar.ubiAI-collapsed {
    width: 0;
  }
  
  /* Sidebar toggle button */
  .ubiAI-sidebar-toggle {
    position: fixed;
    left: 20px;
    top: 80px;
    z-index: 1001;
    background: var(--ubiAI-card-bg);
    border: 1px solid var(--ubiAI-border);
    border-radius: 8px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease;
    color: var(--ubiAI-text);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .ubiAI-sidebar-toggle:hover {
    background: var(--ubiAI-hover);
    color: var(--ubiAI-primary);
    transform: translateY(-1px);
  }
  
  /* Sidebar header */
  .ubiAI-sidebar-header {
    padding: 1.5rem;
    border-bottom: 1px solid var(--ubiAI-border);
    background: var(--ubiAI-card-bg);
  }
  
  .ubiAI-sidebar-header h2 {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    font-size: 1.25rem;
    color: var(--ubiAI-text);
    font-weight: 600;
  }
  
  /* Sidebar content */
  .ubiAI-sidebar-content {
    flex: 1;
    overflow-y: auto;
    padding: 1rem 0;
  }
  
  /* Week container */
  .ubiAI-week-container {
    margin-bottom: 0.5rem;
  }
  
  .ubiAI-week-header {
    width: 100%;
    padding: 0.75rem 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: transparent;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease;
    color: var(--ubiAI-text);
  }
  
  .ubiAI-week-header:hover {
    background: var(--ubiAI-hover);
  }
  
  .ubiAI-week-title {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }
  
  .ubiAI-week-number {
    width: 24px;
    height: 24px;
    background: var(--ubiAI-primary);
    color: white;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem;
    font-weight: 600;
  }
  
  .ubiAI-week-name {
    font-size: 0.9375rem;
    font-weight: 500;
    color: var(--ubiAI-text);
  }
  
  /* Activities list */
  .ubiAI-activities-list {
    padding: 0.5rem 0;
    background: var(--ubiAI-background);
    animation: ubiAI-slideDown 0.3s ease-out;
  }
  
  .ubiAI-activity-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem 1.5rem 0.75rem 3rem;
    transition: all 0.2s ease;
  }
  
  .ubiAI-activity-item:hover {
    background: var(--ubiAI-hover);
  }
  
  .ubiAI-activity-content {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    flex: 1;
    color: var(--ubiAI-secondary-text);
    font-size: 0.875rem;
  }
  
  .ubiAI-activity-content svg {
    color: var(--ubiAI-secondary-text);
    flex-shrink: 0;
  }
  
  .ubiAI-activity-content span {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .ubiAI-activity-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border: none;
    background: transparent;
    color: var(--ubiAI-secondary-text);
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease;
    flex-shrink: 0;
  }
  
  .ubiAI-activity-item:hover .ubiAI-activity-button {
    background: var(--ubiAI-primary);
    color: white;
  }
  
  .ubiAI-activity-button:hover {
    transform: translateX(2px);
  }
  
  .ubiAI-activity-item:hover .ubiAI-activity-content {
    color: var(--ubiAI-text);
  }
  
  .ubiAI-activity-item:hover .ubiAI-activity-content svg {
    color: var(--ubiAI-primary);
  }
  
  /* Loading state */
  .ubiAI-loading {
    padding: 2rem;
    text-align: center;
    color: var(--ubiAI-secondary-text);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 0.9375rem;
  }
  
  /* Scrollbar styling */
  .ubiAI-sidebar-content::-webkit-scrollbar {
    width: 6px;
  }
  
  .ubiAI-sidebar-content::-webkit-scrollbar-track {
    background: var(--ubiAI-background);
  }
  
  .ubiAI-sidebar-content::-webkit-scrollbar-thumb {
    background: var(--ubiAI-secondary-text);
    border-radius: 3px;
  }
  
  .ubiAI-sidebar-content::-webkit-scrollbar-thumb:hover {
    background: var(--ubiAI-text);
  }
  
  /* Animations */
  @keyframes ubiAI-slideDown {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Main content adjustment */
  .ubiAI-main-content.with-sidebar {
    margin-left: 320px;
    transition: margin-left 0.3s ease;
  }
  
  .ubiAI-main-content:not(.with-sidebar) {
    margin-left: 0;
    transition: margin-left 0.3s ease;
  }
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    .ubiAI-sidebar {
      width: 280px;
    }
  
    .ubiAI-sidebar.ubiAI-collapsed {
      width: 0;
    }
  
    .ubiAI-main-content.with-sidebar {
      margin-left: 280px;
    }
  }
  
  @media (max-width: 768px) {
    .ubiAI-sidebar {
      width: 260px;
    }
  
    .ubiAI-sidebar-toggle {
      top: 70px;
    }
  
    .ubiAI-sidebar-header {
      padding: 1rem;
    }
  
    .ubiAI-week-header {
      padding: 0.75rem 1rem;
    }
  
    .ubiAI-activity-item {
      padding: 0.75rem 1rem 0.75rem 2.5rem;
    }
  
    .ubiAI-activity-button {
      width: 24px;
      height: 24px;
    }
  
    .ubiAI-week-number {
      width: 22px;
      height: 22px;
      font-size: 0.8125rem;
    }
  
    .ubiAI-week-name {
      font-size: 0.875rem;
    }
  
    .ubiAI-activity-content {
      font-size: 0.8125rem;
      gap: 0.5rem;
    }
  }
  
  @media (max-width: 480px) {
    .ubiAI-sidebar {
      width: 240px;
    }
  
    .ubiAI-sidebar-toggle {
      top: 60px;
    }
  
    .ubiAI-sidebar-header h2 {
      font-size: 1.125rem;
    }
  
    .ubiAI-week-header {
      padding: 0.625rem 0.875rem;
    }
  
    .ubiAI-activity-item {
      padding: 0.625rem 0.875rem 0.625rem 2.25rem;
    }
  
    .ubiAI-activity-button {
      width: 22px;
      height: 22px;
    }
  }
  