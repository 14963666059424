/* SSS Başlık Resmi */
.faq-header-image {
    width: 100%;
    height: 70vh; /* Yüksekliği biraz küçülttük */
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px; /* Kenarları yuvarladık */
  }
  
  .faq-header-img {
    width: 90%; /* Genişliği biraz küçülttük */
    height: 90%; /* Yüksekliği biraz küçülttük */
    object-fit: cover;
  }
  
  /* SSS Bölümü */
  .faq-container {
    font-family: 'Poppins', sans-serif;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;
  }
  
  .faq-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
    gap: 12px;
  }
  
  .faq-title {
    font-size: 1.8rem;
    font-weight: 500;
    color: #fb8500;
  }
  
  .faq-card {
    width: 100%;
    max-width: 66.67%;
    background-color: #FCE0A2;
    border-radius: 8px;
    padding: 16px 24px;
    transition: background-color 0.3s ease;
  }
  
  .faq-card:hover {
    background-color: #fad37f;
  }
  
  .faq-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
  }
  
  .faq-question {
    font-size: 1.25rem;
    font-weight: 500;
    color: #301934;
  }
  
  .faq-button {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.25rem;
    color: #FB8500;
    border-radius: 50%;
    border: none;
    background: none; /* Arka planı kaldırıyoruz */
    transition: color 0.3s ease;
  }
  
  .faq-button:hover {
    color: #ff6b00; /* Hover durumunda renk değişimi */
  }
  
  .faq-button.bg-orange-logo {
    color: #FB8500;
  }
  
  /* Diğer CSS stilleri aynı kalabilir */
  
  .faq-button.bg-orange-logo {
    color: #fb8500;
  }
  
  .faq-content {
    margin-top: 16px;
    font-size: 1rem;
    color: #2B2D42;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s ease;
  }
  
  .faq-content.show {
    max-height: 200px; /* İçeriğin maksimum yüksekliğini ayarlayabilirsiniz */
  }

/* Media Queries */
@media (max-width: 1024px) {
  .faq-card {
    max-width: 90%;
  }

  .faq-question {
    font-size: 1.125rem; /* 1 size smaller */
  }
}

@media (max-width: 468px) {
  .faq-card {
    max-width: 90%;
  }

  .faq-question {
    font-size: 1rem; /* 1 size smaller */
  }

  .faq-button {
    width: 36px; /* Smaller size for smaller screens */
    height: 36px;
    font-size: 1.25rem; /* Adjust icon size accordingly */
    line-height: 36px; /* Ensure the icon is centered */
    padding: 0; /* Remove any padding */
  }
}
