/* Notification Component Styles */

.notification-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #ffffff; /* Background color */
  font-family: 'Poppins', sans-serif;
  color: #301934; /* Text color */
}

.notification-lessons-container {
  width: 100%;
  margin-bottom: 30px;
  background-color: #ffffff; /* Card background */
  border-radius: 12px;       /* Border radius */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Box shadow */
  overflow: hidden;
}

.notification-module-header {
  background-color: #301934; /* Primary color */
  color: white;
  padding: 15px;
  text-align: center;
}

.notification-module-title {
  font-size: 1.5em;
  font-weight: bold;
  margin: 0;
}

.notification-lesson-card {
  border-top: 1px solid #e0e0e0;
  padding: 20px;
  transition: all 0.3s ease; /* Transition */
}

.notification-lesson-card.accessible:hover {
  background-color: #fbf1e6;
}

.notification-lesson-card.locked {
  background-color: #f9f9f9;
  color: #888;
}

.notification-lesson-header {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.notification-week-number-circle {
  background-color: #fb8500; /* Secondary color */
  color: white;
  font-weight: bold;
  font-size: 1.2em;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 20px;
  flex-shrink: 0;
}

.notification-lesson-info {
  flex-grow: 1;
}

.notification-lesson-name {
  font-weight: 600;
  margin: 0 0 5px;
  font-size: 1.1em;
}

.notification-lesson-header p {
  margin: 0;
  color: #666;
  font-size: 0.9em;
}

.notification-arrow {
  transition: all 0.3s ease; /* Transition */
  font-size: 1.2em;
  color: #fb8500; /* Secondary color */
}

.notification-arrow.expanded {
  transform: rotate(180deg);
}

.notification-activities-container {
  overflow: hidden;
  transition: all 0.3s ease; /* Transition */
  padding: 0 20px;
}

.notification-activity-item {
  display: flex;
  align-items: center;
  margin: 15px 0;
  padding: 10px;
  background-color: #301934; /* Primary color */
  border-radius: 12px;       /* Border radius */
  color: white;
}

.notification-activity-number-circle {
  background-color: #fb8500; /* Secondary color */
  color: white;
  font-weight: bold;
  font-size: 1em;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 15px;
  flex-shrink: 0;
}

.notification-activity-item span {
  flex-grow: 1;
  font-size: 0.95em;
}

.notification-common-button {
  font-family: 'Poppins', sans-serif;
  background-color: #fb8500; /* Secondary color */
  color: white;
  padding: 8px 15px;
  border-radius: 20px;
  text-decoration: none;
  font-size: 0.9em;
  transition: all 0.3s ease; /* Transition */
  border: none;
  cursor: pointer;
  display: inline-block;
  text-align: center;
}

.notification-common-button:hover {
  background-color: #e67a00;
  transform: translateY(-2px);
}

.notification-locked-section {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
}

.notification-locked-message {
  color: #ff4d4d;
  font-weight: 500;
  font-size: 0.9em;
  margin-right: 15px;
}

.notification-slide-button {
  background-color: #301934; /* Primary color */
}

.notification-slide-button:hover {
  background-color: #4a2865;
}

@media (max-width: 768px) {
  .notification-lesson-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .notification-week-number-circle {
    margin-bottom: 10px;
  }

  .notification-locked-section {
    flex-direction: column;
    align-items: flex-start;
  }

  .notification-locked-message {
    margin-bottom: 10px;
  }
}
