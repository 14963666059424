/* Main Container */
.course-container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 2rem;
  font-family: 'Poppins', sans-serif;
  color: #111827;
}

/* Tabs Navigation */
.tabs-wrapper {
  display: flex;
  gap: 0.75rem;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid #e5e7eb;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scroll-behavior: smooth;
}

.tabs-wrapper::-webkit-scrollbar {
  display: none;
}

.tab-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.25rem;
  border-radius: 9999px;
  font-weight: 500;
  font-size: 0.875rem;
  color: #6b7280;
  background: white;
  border: 1px solid #e5e7eb;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
}

.tab-button:hover {
  border-color:  #fb860076;
  color:  #fb8500;
  background-color: #fffbf5;
}

.tab-button.active {
  background:  #fb8500;
  color: white;
  border-color:  #fb8500;
}

.tab-button-icon {
  width: 1.25rem;
  height: 1.25rem;
  transition: transform 0.2s ease;
}

.tab-button:hover .tab-button-icon {
  transform: scale(1.1);
}

/* Tab Content */
.tab-content {
  animation: fadeIn 0.3s ease-in-out;
  background: white;
  border-radius: 1rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 
              0 2px 4px -1px rgba(0, 0, 0, 0.06);
  overflow: hidden;
}

.tab-content-container {
  background: white;
}

/* Tab Header */
.tab-header {
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
  padding: 2rem 2rem 1.5rem 2rem;
  border-bottom: 1px solid #f3f4f6;
  background: linear-gradient(to right, #ffffff, #fafafa);
}

.tab-header-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  background: #fb8500;
  border-radius: 0.75rem;
  flex-shrink: 0;
  box-shadow: 0 4px 6px -1px rgba(79, 70, 229, 0.2);
}

.tab-icon {
  width: 1.5rem;
  height: 1.5rem;
  color: white;
}

.tab-header-text {
  flex-grow: 1;
}

.tab-title {
  font-size: 1.5rem;
  font-weight: 700;
  color: #111827;
  margin: 0 0 0.5rem 0;
  line-height: 1.2;
}

.tab-description {
  font-size: 1rem;
  color: #6b7280;
  line-height: 1.5;
  margin: 0;
}

/* Features Grid */
.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 1.5rem;
  padding: 1.5rem 2rem 2rem 2rem;
}

/* Feature Card */
.feature-card {
  background: #f9fafb;
  border-radius: 1rem;
  padding: 1.5rem;
  transition: all 0.3s ease;
  border: 1px solid #f3f4f6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.feature-card:hover {
  background: white;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
              0 2px 4px -1px rgba(0, 0, 0, 0.06);
  transform: translateY(-2px);
}

.icon-title-wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.icon-container2 {
  flex-shrink: 0;
  width: 3rem;
  height: 3rem;
  background: #fb8500;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.feature-card:hover .icon-container {
  transform: scale(1.1);
}

.feature-icon {
  font-size: 1.5rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
}

.feature-number {
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-container {
  flex-grow: 1;
  min-width: 0;
}

.feature-title {
  color: #111827;
  font-size: 1rem;
  font-weight: 600;
  margin: 0 0 0.25rem 0;
  line-height: 1.4;
}

.feature-description {
  color: #6b7280;
  font-size: 0.875rem;
  line-height: 1.5;
  margin: 0;
}

/* Accordion Styles */
.accordion-container {
  padding: 1.5rem 2rem 2rem 2rem;
}

.accordion-item {
  border-bottom: 1px solid #e5e7eb;
}

.accordion-item:last-child {
  border-bottom: none;
}

.accordion-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem 0;
  background: none;
  border: none;
  cursor: pointer;
  text-align: left;
  transition: all 0.2s ease;
}

.accordion-header:hover {
  color: #fb8500;
}

.accordion-question {
  font-size: 1.125rem;
  font-weight: 500;
  color: #111827;
  transition: color 0.2s ease;
  padding-right: 1rem;
  flex-grow: 1;
}

.accordion-icon {
  width: 1.25rem;
  height: 1.25rem;
  color: #6b7280;
  transition: all 0.3s ease;
  flex-shrink: 0;
}

.accordion-icon.rotate {
  transform: rotate(180deg);
  color:  #fb8500;
}

.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  opacity: 0;
}

.accordion-content.show {
  max-height: 500px;
  opacity: 1;
  padding-bottom: 1.25rem;
}

.accordion-answer {
  color: #6b7280;
  font-size: 1rem;
  line-height: 1.6;
  padding-right: 2.5rem;
}

/* Active States */
.accordion-header.active .accordion-question {
  color:  #fb8500;
  font-weight: 600;
}

.accordion-header.active .accordion-icon {
  color:  #fb8500;
}

/* Focus States */
.tab-button:focus,
.accordion-header:focus {
  outline: none;
  ring: 2px solid  #fb8500;
  ring-offset: 2px;
}

.tab-button:focus:not(:focus-visible),
.accordion-header:focus:not(:focus-visible) {
  ring: none;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .course-container {
    padding: 1rem;
  }

  .tab-button {
    padding: 0.625rem 1rem;
    font-size: 0.75rem;
  }

  .tab-header {
    padding: 1.5rem 1.5rem 1rem 1.5rem;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .tab-header-icon {
    width: 2.5rem;
    height: 2.5rem;
    margin-bottom: 1rem;
  }

  .tab-icon {
    width: 1.25rem;
    height: 1.25rem;
  }

  .tab-title {
    font-size: 1.25rem;
  }

  .tab-description {
    font-size: 0.875rem;
  }

  .accordion-container {
    padding: 1rem 1.5rem 1.5rem 1.5rem;
  }

  .accordion-question {
    font-size: 1rem;
  }

  .accordion-answer {
    font-size: 0.875rem;
    padding-right: 1.5rem;
  }

  .features-grid {
    grid-template-columns: 1fr;
    padding: 1rem 1.5rem 1.5rem 1.5rem;
    gap: 1rem;
  }
  
  .feature-card {
    padding: 1rem;
  }

  .icon-container {
    width: 2rem;
    height: 2rem;
  }

  .feature-icon {
    width: 1rem;
    height: 1rem;
  }

  .feature-number {
    font-size: 1.25rem;
  }
}

/* Small Screen Adjustments */
@media (max-width: 480px) {
  .tab-button {
    padding: 0.5rem 0.75rem;
    font-size: 0.7rem;
  }

  .tab-button-icon {
    width: 1rem;
    height: 1rem;
  }

  .accordion-question {
    font-size: 0.875rem;
  }

  .accordion-answer {
    padding-right: 1rem;
  }

  .feature-title {
    font-size: 0.875rem;
  }

  .feature-description {
    font-size: 0.75rem;
  }

  .feature-number {
    font-size: 1rem;
  }
}

/* Print Styles */
@media print {
  .course-container {
    padding: 0;
  }

  .tabs-wrapper {
    display: none;
  }

  .tab-content {
    box-shadow: none;
  }

  .feature-card:hover {
    transform: none;
    box-shadow: none;
  }
}
