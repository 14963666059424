/* Tüm sayfa düzeni */
.container {
  display: flex;
  height: 100vh; /* Sayfanın tamamını kapla */
  width: 100vw;
  overflow: hidden; /* Genel sayfa için taşmayı gizle */
}

/* Sol bölüm: Bilgilendirici kısım */
.left-section {
  background-color: #301934;
  color: white;
  width: 50%; /* Sayfanın yarısı */
  padding: 60px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden; /* Sol kısım kaydırmasız sabit */
}
.required {
  color: red;
  margin-left: 2px;
}


.left-section .logo {
  width: 150px;
  margin-bottom: 30px;
}

.left-section h1 {
  font-size: 32px;
  margin-bottom: 20px;
}

.left-section p {
  font-size: 18px;
  margin-bottom: 10px;
}

.left-section ul {
  list-style-type: disc;
  margin-left: 20px;
  font-size: 16px;
  margin-bottom: 30px;
}

.left-section a.support-btn {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  text-align: center;
  margin-bottom: 20px;
  width: fit-content;
}

.left-section a.support-btn:hover {
  background-color: #0056b3;
}

.faq-btn {
  background-color: transparent;
  border: 1px solid white;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  width: fit-content;
}

.faq-btn:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

/* Sağ bölüm: Ödeme formu */
.right-section {
  width: 50%; /* Sayfanın diğer yarısı */
  padding: 40px; /* Üstteki boşlukları azaltıyoruz */
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Formu en üste hizala */
  align-items: flex-start; /* Başlığı sola hizala */
  overflow-y: auto; /* Kaydırma özelliği sadece sağ tarafa */
}

.payment-form {
  width: 100%; /* Formun genişliğini tam genişlikte yap */
}

.payment-form h2 {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 24px;
  color: #333;
  margin-top: 20px; /* Formun üst kısmını aşağı indiriyoruz */
}

.form-group {
  margin-bottom: 20px;
}

.payment-form label {
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin-bottom: 5px;
  display: block;
}

.payment-form input,
.payment-form select {
  width: 100%;
  padding: 14px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 16px;
  background-color: #f9f9f9;
  transition: all 0.3s ease;
}

.payment-form input:focus,
.payment-form select:focus {
  border-color: #00aaff;
  background-color: #fff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 170, 255, 0.5);
}

.payment-form button {
  background-color: #fb8500; /* Turuncu */
  color: white;
  padding: 16px;
  border: none;
  border-radius: 6px;
  font-size: 18px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s ease;
  font-weight: bold;
}

.payment-form button:hover {
  background-color: #e67300; /* Daha koyu turuncu */
}

.amount-box {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #fb8500; /* Turuncu kutu */
  color: white;
  padding: 15px 25px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}


.payment-form .expiry {
  display: flex;
  gap: 10px;
}

.payment-form p {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
  color: #666;
}
/* İndirim butonu ve bölüm */
.discount-link {
  color: #fb8500;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 10px;
}

.discount-link:hover {
  color: #e67300;
}

.discount-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Sol hizalı yapıldı */
  margin-top: 15px;
}

.discount-section input {
  padding: 10px;
  margin-bottom: 10px; /* Butonun üstüne boşluk ekledik */
  flex: 1;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.apply-btn {
  background-color: #fb8500;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  align-self: flex-end; /* Butonu sağa yasladık */
}

.apply-btn:hover {
  background-color: #e67300;
}

.payment-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.pay-btn {
  background-color: #fb8500;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.pay-btn:hover {
  background-color: #e67300;
}

/* Ödenecek Tutar kutusunu sabitleme ve turuncu arka plan */
.amount-box {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #fb8500;
  color: white;
  padding: 15px 25px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

/* Genel Stil Ayarları */
.terms-checkbox {
  display: flex;
  align-items: center;
  margin-top: 20px;
}


.terms-checkbox input {
  margin-right: 10px;
  width: 20px;  /* Checkbox boyutunu ayarla */
  height: 20px; /* Checkbox boyutunu ayarla */
  margin-right: 10px; /* Cümleden aralığını ayarla */
  vertical-align: middle; /* Checkbox'ı ortalayalım */
}

.terms-text {
  font-size: 14px;
}

.link {
  color: #fb8500;
  cursor: pointer;
  text-decoration: underline;
}

.link:hover {
  color: #e67300;
}

.pay-btn {
  background-color: #fb8500;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.pay-btn.disabled-btn {
  background-color: #ccc;
  cursor: not-allowed;
}

.pay-btn:hover {
  background-color: #e67300;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}

/* Popup Stil */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  max-width: 600px;
  width: 90%;
  max-height: 80vh;
  overflow-y: auto;
}

.close-button {
  background-color: #fb8500;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.close-button:hover {
  background-color: #e57a00;
}




/* Mobil görünüm */
@media only screen and (max-width: 768px) {
  .container {
    flex-direction: column; /* Sol ve sağ tarafı alt alta yerleştir */
    height: auto;
    overflow: visible;
  }

  .left-section {
    width: 100%; /* Sol bölüm tam genişlikte olacak */
    padding: 20px;
  }

  .right-section {
    width: 100%; /* Sağ bölüm tam genişlikte olacak */
    padding: 20px;
    overflow-y: visible; /* Sağ bölümde kaydırma yerine içeriğin tamamı görünsün */
  }

  .payment-form h2 {
    margin-top: 10px;
  }

  .amount-box {
    position: relative; /* Mobilde sabitlemeyi kaldırıyoruz */
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
  }

  .discount-section {
    flex-direction: column; /* İndirim kodu ve butonu üst üste gelecek */
    align-items: flex-start;
  }

  .apply-btn {
    align-self: flex-start;
  }

  .pay-btn {
    width: 100%; /* Ödeme butonunu tam genişlikte yapalım */
  }
}