.CoursesSectionCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  border-radius: 40px;
  margin-top: 20px;
  width: 375px;
  perspective: 1000px;
  transform-style: preserve-3d;
  transition: transform 0.6s;
}
.courses-title {
  font-size: 3rem; /* Aynı boyut */
  font-weight: 700; /* Aynı kalınlık */
  margin-bottom: 20px;
  color: #301934; /* Aynı renk */
  position: relative;
  display: inline-block;
}

.courses-title-highlight {
  color: #f5841f;
  position: relative;
}

.courses-title-highlight::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 4px;
  bottom: -4px;
  left: 0;
  background-color: #f5841f;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.courses-title-highlight:hover::after {
  transform: scaleX(1);
}


.CoursesSectionCard.flipped {
  transform: rotateY(180deg);
}

.card-front, .card-back {
  backface-visibility: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 40px;
  padding: 20px;
  box-sizing: border-box;
}

.card-back {
  transform: rotateY(180deg);
}

.CoursesSectionParentCard {
  scroll-behavior: smooth;
}

.CoursesSectionParentCard::-webkit-scrollbar {
  height: 12px;
}

.CoursesSectionParentCard::-webkit-scrollbar-thumb {
  background-color: #FB8500;
  border-radius: 6px;
}

.CoursesSectionParentCard::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 6px;
}

@media screen and (min-width: 1024px) {
  .CoursesSectionParentCard {
    justify-content: center;
  }
}

@media screen and (max-width: 1024px) and (min-width: 468px) {
  .CoursesSectionParentCard {
    display: flex;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    padding-left: 20px;
    gap: 10px;
  }
  .CoursesSectionCard {
    flex: 0 0 auto;
    width: 320px;
    margin-right: 10px;
    scroll-snap-align: start;
  }
}

@media screen and (max-width: 468px) {
  .courses-title {
      font-size: 1.9rem; /* Mobilde aynı boyut */
    
  }
  
  .CoursesSectionParentCard {
    flex-direction: column;
    overflow-x: hidden;
    padding-left: 0;
    gap: 20px;
  }
  .CoursesSectionCard {
    width: 115%;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .CoursesSectionCard2 {
    width: 100%;
  }
  .CoursesSectionMarCard {
    margin-top: 0;
  }
}

.view-curriculum-btn, .hide-curriculum-btn {
  font: poppins;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FB8500;
  border: none;
  background: none;
  cursor: pointer;
  margin-top: -20px;
}


.enroll-now-btn {
  background-color: #FB8500;
  color: white;
  width: 60%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  border-radius: 20px;
  cursor: pointer;
  text-align: center;
}

.curriculum-item {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  font-family: poppins;
}

.curriculum-week {
  font-family: poppins;
  background-color: #FB8500;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 10px;
}

.icon {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 80px;
  height: 65px;
}
