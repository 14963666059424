.score-container {
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 100%;
    background-color: #fb8500;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    writing-mode: vertical-rl;
    text-align: center;
    font-size: 1.2em;
    font-weight: bold;
    border-radius: 0 12px 12px 0;
  }
  