.quiz-popup-overlay {
    position: fixed;
    inset: 0;
    background-color: rgba(17, 24, 39, 0.5);
    backdrop-filter: blur(4px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 50;
    animation: fadeIn 0.2s ease-out;
  }
  
  .quiz-popup-container {
    background-color: white;
    border-radius: 1rem;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
                0 10px 10px -5px rgba(0, 0, 0, 0.04);
    width: 100%;
    max-width: 32rem;
    margin: 1rem;
    overflow: hidden;
    transform: translateY(0);
    animation: slideUp 0.3s ease-out;
  }
  
  .quiz-popup-content {
    padding: 1.5rem;
  }
  
  .quiz-popup-header {
    text-align: center;
    margin-bottom: 1.5rem;
  }
  
  .quiz-popup-icon {
    width: 4rem;
    height: 4rem;
    margin: 0 auto 1rem;
  }
  
  .quiz-popup-icon.success {
    color: #eab308;
  }
  
  .quiz-popup-icon.default {
    color: #3b82f6;
  }
  
  .quiz-popup-title {
    font-size: 1.5rem;
    font-weight: 700;
    color: #111827;
  }
  
  .quiz-popup-score-circle {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
  }
  
  .quiz-popup-score-container {
    position: relative;
    width: 8rem;
    height: 8rem;
  }
  
  .quiz-popup-score-svg {
    width: 8rem;
    height: 8rem;
  }
  
  .quiz-popup-score-background {
    stroke: #e5e7eb;
    fill: transparent;
    stroke-width: 8;
  }
  
  .quiz-popup-score-progress {
    stroke-width: 8;
    stroke-linecap: round;
    fill: transparent;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    transition: stroke-dashoffset 0.3s ease;
  }
  
  .quiz-popup-score-progress.success {
    stroke: #22c55e;
  }
  
  .quiz-popup-score-progress.default {
    stroke: #3b82f6;
  }
  
  .quiz-popup-score-text {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.875rem;
    font-weight: 700;
    color: #111827;
  }
  
  .quiz-popup-stats {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .quiz-popup-stat-item {
    text-align: center;
    padding: 0.75rem;
    border-radius: 0.75rem;
  }
  
  .quiz-popup-stat-item.correct {
    background-color: #f0fdf4;
  }
  
  .quiz-popup-stat-item.wrong {
    background-color: #fef2f2;
  }
  
  .quiz-popup-stat-item.empty {
    background-color: #f8fafc;
  }
  
  .quiz-popup-stat-icon {
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 auto 0.25rem;
  }
  
  .quiz-popup-stat-icon.correct {
    color: #22c55e;
  }
  
  .quiz-popup-stat-icon.wrong {
    color: #ef4444;
  }
  
  .quiz-popup-stat-icon.empty {
    color: #94a3b8;
  }
  
  .quiz-popup-stat-value {
    font-size: 1.25rem;
    font-weight: 700;
  }
  
  .quiz-popup-stat-value.correct {
    color: #15803d;
  }
  
  .quiz-popup-stat-value.wrong {
    color: #b91c1c;
  }
  
  .quiz-popup-stat-value.empty {
    color: #475569;
  }
  
  .quiz-popup-stat-label {
    font-size: 0.875rem;
    color: #6b7280;
  }
  
  .quiz-popup-status {
    text-align: center;
    padding: 0.75rem;
    border-radius: 0.75rem;
    margin-bottom: 1.5rem;
  }
  
  .quiz-popup-status.success {
    background-color: #f0fdf4;
    color: #15803d;
  }
  
  .quiz-popup-status.error {
    background-color: #fef2f2;
    color: #b91c1c;
  }
  
  .quiz-popup-actions {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
  
  .quiz-popup-button {
    width: 100%;
    padding: 0.75rem 1rem;
    border-radius: 0.75rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    transition: all 0.2s ease;
  }
  
  .quiz-popup-button.primary {
    background-color: #3b82f6;
    color: white;
  }
  
  .quiz-popup-button.primary:hover {
    background-color: #2563eb;
    transform: translateY(-2px);
  }
  
  .quiz-popup-button.secondary {
    background-color: #f1f5f9;
    color: #475569;
  }
  
  .quiz-popup-button.secondary:hover {
    background-color: #e2e8f0;
  }
  
  .quiz-popup-button-icon {
    width: 1rem;
    height: 1rem;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes slideUp {
    from {
      opacity: 0;
      transform: translateY(1rem);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @media (max-width: 640px) {
    .quiz-popup-container {
      margin: 1rem;
    }
    
    .quiz-popup-content {
      padding: 1.25rem;
    }
    
    .quiz-popup-score-container {
      width: 6rem;
      height: 6rem;
    }
    
    .quiz-popup-score-svg {
      width: 6rem;
      height: 6rem;
    }
    
    .quiz-popup-score-text {
      font-size: 1.5rem;
    }
  }