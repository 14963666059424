.participation-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100px;
    position: absolute;
    top: 541px;
    left: 60px;
    height: 100%;
    background-color: #301934;
    color: white;
    padding: 40px 0;
    border-radius: 12px 0 0 12px;
  }
  
  .participation-week {
    display: flex;
    min-height: 83px;
    align-items: center;
    margin-bottom: 10px;
    flex-direction: column;
    font-size: 0.9em;
    font-weight: bold;
  }
  
  .status-icon {
    font-size: 1.5em;
    margin-bottom: 5px;
  }
  
  .green-circle {
    color: #4CAF50; /* Yeşil */
  }
  
  .red-circle {
    color: #F44336; /* Kırmızı */
  }
  
  .yellow-circle {
    color: #FFEB3B; /* Sarı */
  }
  