    .sidebar {
        background-color: #0a2540; /* TrackCard arka plan rengini kullanıyoruz */
        color: white;
        width: 250px;
        transition: margin-left 0.5s ease-in-out;
        position: relative;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 40;
        overflow-y: auto;
    }

    .bg-dark-blue {
        background-color: #301934;
    }

    .bg-selected {
        background-color: #fb8500;
    }

    .text-light-gray {
        color: #E5E4E2;
    }

    .bg-overlay {
        background-color: rgba(10, 37, 64, 0.8);
    }

    img.rounded-full {
        border-radius: 50%;
    }

    a {
        text-decoration: none;
    }

    .flex {
        display: flex;
    }

    .items-center {
        align-items: center;
    }

    .mb-1 {
        margin-bottom: 1rem;
    }

    .py-2 {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    .p-10 {
        padding: 2.5rem;
    }

    .mt-4 {
        margin-top: 1rem;
    }

    .gap-small {
        gap: 0.25rem;
    }

    .text-md {
        font-size: 1rem;
    }

    .px-4 {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .rounded-lg {
        border-radius: 0.5rem;
    }

    .cursor-pointer {
        cursor: pointer;
    }

    .text-xl {
        font-size: 1.25rem;
    }

    .width-25px {
        width: 25px;
    }

    .text-lg {
        font-size: 1.125rem;
    }

    .font-poppins {
        font-family: 'Poppins', sans-serif;
    }

    .font-semibold {
        font-weight: 600;
    }

    .font-normal {
        font-weight: 400;
    }

    .md-hidden {
        display: none;
    }

    .md-static {
        position: static;
    }

    .md-ml-0 {
        margin-left: 0;
    }

    .md-flex {
        display: flex;
    }

    .transition-margin {
        transition: margin-left 0.5s ease-in-out;
    }

    .ml-0 {
        margin-left: 0;
    }

    .ml-negative {
        margin-left: -250px;
    }

    .text-2xl {
        font-size: 1.5rem;
    }
