.create-class {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.create-class h2 {
  text-align: center;
  margin-bottom: 20px;
}

.tabs {
  display: flex;
  margin-bottom: 20px;
}

.tabs button {
  flex: 1;
  padding: 12px;
  border: none;
  background-color: #e0e0e0;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
}

.tabs button.active {
  background-color: #301934;
  color: white;
}

.tabs button:not(:last-child) {
  border-right: 1px solid #ccc;
}

.tabs button:hover {
  background-color: #ccc;
}

.tab-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
}

.tab-panel {
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.btn-primary,
.btn-danger {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
}

.btn-primary {
  background-color: #007bff;
  color: #fff;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.btn-danger {
  background-color: red;
  color: white;
}

.btn-danger:hover {
  background-color: darkred;
}


.popup-content.success {
  border-left: 5px solid #28a745; /* Yeşil renk */
}

.popup-content.error {
  border-left: 5px solid #dc3545; /* Kırmızı renk */
}

.popup-content p {
  font-size: 18px;
  margin: 0;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 24px;
  cursor: pointer;
  color: #999;
}

.close-button:hover {
  color: #666;
}
