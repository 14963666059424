.track-cards {
  width: 100%;
  max-width: 1200px; /* lessons-container ile aynı genişlik */
  margin: 0 auto; /* Ortalamak için */
  padding: 20px 0; /* Üst ve alt boşluk */
}

.track-card {
  background-color: #301934;
  color: white;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px; /* Kartlar arasındaki boşluk */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%; /* Kartın genişliği lessons-container ile aynı */
}

.track-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.track-title {
  font-size: 14px;
  color: #e5e4e2;
}

.track-header h1 {
  font-size: 28px;
  margin: 10px 0;
  font-weight: bold;
}

.continue-button {
  background-color: #fb8500;
  color: white;
  border: none;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.continue-button:hover {
  background-color: #ffa238;
}

.track-info {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
}

.track-meta {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.track-meta-item {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
}

.track-meta-item img {
  width: 32px;
  height: 32px;
}

.track-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #fb8500;
  padding-top: 20px;
}

.track-progress {
  display: flex;
  align-items: center;
  gap: 10px;
}

.track-progress span {
  font-size: 14px;
}

.progress-bar {
  background-color: #1e3a5f;
  width: 200px;
  height: 10px;
  border-radius: 5px;
  overflow: hidden;
}

.progress {
  background-color: #fb8500;
  height: 100%;
}

.bonus-material {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.bonus-material span {
  font-size: 14px;
}

.bonus-icons {
  display: flex;
  gap: 5px;
  margin-top: 5px;
}

.bonus-icon {
  background-color: #1e3a5f;
  padding: 5px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
