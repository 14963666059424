.login-page {
  display: flex;
  justify-content: flex-end; /* Ekranın sağ tarafına yerleştirir */
  align-items: center;
  height: 100vh;
  background: url('orange_kid.png') no-repeat center center;
  background-size: cover;
  font-family: poppins;
  position: relative;
  padding-right: 100px; /* Sağda biraz boşluk bırakır */
}

.login-container {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 12px;
  padding: 40px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 400px; /* Genişliği artırır */
  text-align: center;
}

.login-logo {
  width: 100px; /* Logonun genişliğini ayarlar */
  margin-bottom: 30px; /* Logonun altındaki boşluğu ayarlar */
  margin-left: 110px;
}

.login-error-popup {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(255, 0, 0, 0.8); /* Kırmızı transparan arka plan */
  color: white;
  padding: 10px 20px;
  border-radius: 8px;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 90%;
  min-width: 300px;
  font-size: 16px;
  font-weight: bold;
  animation: fadein 0.5s, fadeout 0.5s 4.5s; /* Fade in and out animation */
}

@keyframes fadein {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeout {
  from { opacity: 1; }
  to { opacity: 0; }
}

.login-error-popup span {
  flex: 1;
}

.login-error-close {
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  margin-left: 20px;
  cursor: pointer;
  font-weight: bold;
}

.login-input {
  margin-bottom: 20px;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #fb8500;
  border-radius: 10px;
  width: 100%;
}

.login-password-container {
  position: relative;
}

.login-toggle-password {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  color: #007bff;
  font-size: 16px;
}

.login-button {
  padding: 12px;
  font-size: 16px;
  color: #fff;
  background-color: #fb8500; /* Turuncu renk */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}

.login-button:hover {
  background-color: #d97500; /* Daha koyu turuncu */
}

.login-remember-me {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.login-remember-me label {
  font-size: 14px;
  color: #333;
}

.login-checkbox {
  margin-right: 10px;
}

.login-forgot-password {
  font-size: 14px;
  color: #007bff;
  text-decoration: none;
}

.login-forgot-password:hover {
  text-decoration: underline;
}
