/* TopicDetail.css */
.topic-detail-container {
  padding: 35px;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.topic-detail-container h2 {
  color:#fb8500;
  margin-bottom: 16px;
  font-size: larger;
}

.topic-detail-container p {
  line-height: 1.6;
}

.question-section {
  margin-top: 24px;
  padding: 16px;
  background-color: #fce0a2;
  border-radius: 8px;
}

.question-section h3 {
  color: #fb8500;
}

.options {
  margin-top: 16px;
}

.option {
  background-color:white; /* #fce0a2 şeffaf */
  border: 1px solid #fb8500;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
}

.option:hover {
  background-color: #fb8500;
}

.option input {
  margin-right: 12px;
}

.option label {
  flex: 1;
  color: black;
}


.submit-button {
  margin-top: 16px;
  padding: 8px 16px;
  background-color: #fb8500;
  color: white;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
}

.submit-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.submit-button:hover:enabled {
  background-color: #ff9800;
}

.result {
  margin-top: 16px;
  padding: 8px 16px;
  border-radius: 8px;
  font-weight: bold;
}

.correct {
  color: green;
}

.incorrect {
  color: red;
}
