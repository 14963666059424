.quiz-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 24px;
  font-family: system-ui, -apple-system, sans-serif;
  background-color: #f8fafc;
}

.quiz-header {
  background: linear-gradient(135deg, #3b82f6 0%, #1e40af 100%);
  color: white;
  padding: 32px;
  border-radius: 16px;
  text-align: center;
  margin-bottom: 32px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.quiz-title {
  font-size: 2.5rem;
  font-weight: 700;
  margin: 0 0 16px 0;
  letter-spacing: -0.5px;
}

.quiz-description {
  font-size: 1.1rem;
  opacity: 0.9;
  margin: 0;
  line-height: 1.5;
}

.questions-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.question-card {
  background: white;
  border-radius: 16px;
  padding: 32px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.question-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.question-header {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  margin-bottom: 24px;
}

.question-number {
  background: #f0f9ff;
  color: #3b82f6;
  padding: 6px 12px;
  border-radius: 999px;
  font-weight: 600;
  font-size: 0.9rem;
}

.question-text {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1f2937;
  margin: 0;
  flex-grow: 1;
  line-height: 1.4;
}

.code-block {
  background-color: #1e1e1e;
  color: #d4d4d4;
  padding: 24px;
  border-radius: 12px;
  margin: 16px 0;
  font-family: 'Consolas', 'Monaco', monospace;
  font-size: 14px;
  line-height: 1.6;
  overflow-x: auto;
}

.code-line {
  min-height: 24px;
  width: 100%;
  white-space: pre;
}

.code-blank {
  display: inline-block;
  min-width: 120px;
  padding: 2px 8px;
  margin: 0 4px;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 2px dashed #666;
  background-color: #2d2d2d;
}

.code-blank.correct-blank {
  background-color: #1a472a;
  border-color: #2ecc71;
  color: #2ecc71;
}

.code-blank.wrong-blank {
  background-color: #4a1919;
  border-color: #e74c3c;
  color: #e74c3c;
}

.drag-over {
  background-color: #2d4a3e;
  border-color: #2ecc71;
}

.options-container.drag-drop {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 12px;
  margin-top: 20px;
  min-height: 60px;
  border: 2px dashed #e5e7eb;
}

.drag-option {
  background-color: #2d2d2d;
  color: #fff;
  padding: 8px 16px;
  border-radius: 6px;
  cursor: move;
  user-select: none;
  transition: all 0.2s ease;
  font-family: 'Consolas', 'Monaco', monospace;
  font-size: 13px;
}

.drag-option:hover {
  background-color: #3d3d3d;
  transform: translateY(-2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.multiple-choice-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 20px;
}

.choice-button {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px;
  border: 2px solid #e5e7eb;
  border-radius: 12px;
  background: white;
  cursor: pointer;
  transition: all 0.2s ease;
  text-align: left;
}

.choice-button:hover:not(:disabled) {
  border-color: #3b82f6;
  background-color: #f8fafc;
  transform: translateX(4px);
}

.choice-button.selected {
  border-color: #3b82f6;
  background-color: #eff6ff;
}

.choice-button.correct {
  border-color: #2ecc71;
  background-color: #f0fdf4;
}

.choice-button.wrong {
  border-color: #e74c3c;
  background-color: #fef2f2;
}

.choice-letter {
  background: #f3f4f6;
  color: #4b5563;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
  font-weight: 600;
  margin-right: 16px;
}

.choice-text {
  flex-grow: 1;
  font-size: 1rem;
  color: #1f2937;
}

.choice-icon {
  font-size: 1.25rem;
  margin-left: 12px;
}

.choice-icon.wrong {
  color: #e74c3c;
}

.explanation-box {
  margin-top: 24px;
  padding: 20px;
  background-color: #f8fafc;
  border: 1px solid #e5e7eb;
  border-radius: 12px;
}

.explanation-box h4 {
  color: #1f2937;
  margin: 0 0 8px 0;
  font-size: 1rem;
}

.explanation-box p {
  margin: 0;
  color: #4b5563;
  font-size: 0.95rem;
  line-height: 1.5;
}

.results-card {
  margin-top: 32px;
  background: white;
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.results-container {
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
}

.submit-button {
  padding: 16px 32px;
  font-size: 1.1rem;
  background: linear-gradient(135deg, #3b82f6 0%, #1e40af 100%);
  color: white;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 600;
}

.submit-button:hover:not(:disabled) {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(59, 130, 246, 0.3);
}

.submit-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.score-container {
  text-align: right;
}

.score-text {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.score-number {
  font-size: 1.5rem;
  font-weight: 700;
  color: #3b82f6;
}

.score-label {
  color: #6b7280;
  font-size: 0.95rem;
}

/* Responsive tasarım için medya sorguları */
@media (max-width: 768px) {
  .quiz-container {
    padding: 16px;
  }

  .quiz-header {
    padding: 24px;
  }

  .quiz-title {
    font-size: 2rem;
  }

  .question-card {
    padding: 24px;
  }

  .results-container {
    flex-direction: column;
    text-align: center;
  }

  .score-container {
    text-align: center;
  }

  .submit-button {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .choice-button {
    padding: 12px;
  }

  .choice-letter {
    width: 28px;
    height: 28px;
    font-size: 0.9rem;
  }

  .choice-text {
    font-size: 0.9rem;
  }

  .code-block {
    font-size: 12px;
    padding: 16px;
  }

  .drag-option {
    padding: 6px 12px;
    font-size: 12px;
  }
}
/* Mevcut CSS'e eklenecek yeni stiller */

.results-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
}

.results-modal {
  background: white;
  border-radius: 24px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
              0 10px 10px -5px rgba(0, 0, 0, 0.04);
  width: 100%;
  max-width: 32rem;
  margin: 1rem;
  overflow: hidden;
  transform: translateY(0);
  animation: modalSlideIn 0.3s ease-out;
}

@keyframes modalSlideIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.results-content {
  padding: 1.5rem;
}

.score-circle {
  position: relative;
  width: 8rem;
  height: 8rem;
  margin: 0 auto 2rem;
}

.score-circle svg {
  transform: rotate(-90deg);
}

.score-circle-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.stat-card {
  padding: 1rem;
  border-radius: 12px;
  text-align: center;
}

.modal-actions {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-top: 1.5rem;
}

.modal-button {
  width: 100%;
  padding: 0.75rem 1.5rem;
  border-radius: 12px;
  font-weight: 600;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.modal-button-primary {
  background: linear-gradient(135deg, #3b82f6 0%, #1e40af 100%);
  color: white;
}

.modal-button-primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(59, 130, 246, 0.3);
}

.modal-button-secondary {
  background-color: #f3f4f6;
  color: #4b5563;
}

.modal-button-secondary:hover {
  background-color: #e5e7eb;
}

@media (max-width: 640px) {
  .results-modal {
    margin: 1rem;
    max-width: calc(100% - 2rem);
  }
  
  .score-circle {
    width: 6rem;
    height: 6rem;
  }
  
  .stats-grid {
    gap: 0.75rem;
  }
}
/* Ek stil tanımlamaları */
.submit-container {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

.submit-button {
  padding: 1rem 2.5rem;
  font-size: 1.125rem;
  font-weight: 600;
  color: white;
  background: linear-gradient(135deg, #3b82f6 0%, #1e40af 100%);
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  min-width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.submit-button:hover:not(:disabled) {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(59, 130, 246, 0.3);
}

.submit-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  background: #94a3b8;
}

.results-summary {
  margin-top: 2rem;
  padding: 1.5rem;
  background: white;
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.results-stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background: #f8fafc;
  border-radius: 12px;
  transition: transform 0.2s ease;
}

.stat-item:hover {
  transform: translateY(-2px);
}

.stat-label {
  font-size: 0.875rem;
  color: #64748b;
  margin-bottom: 0.5rem;
}

.stat-value {
  font-size: 1.5rem;
  font-weight: 700;
  color: #1e293b;
}

.stat-value.correct {
  color: #22c55e;
}

.stat-value.wrong {
  color: #ef4444;
}

.stat-value.empty {
  color: #94a3b8;
}

.results-actions {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
  flex-wrap: wrap;
}

.result-action-button {
  flex: 1;
  min-width: 200px;
  padding: 1rem 1.5rem;
  border-radius: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.result-action-button.primary {
  background: linear-gradient(135deg, #3b82f6 0%, #1e40af 100%);
  color: white;
}

.result-action-button.primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(59, 130, 246, 0.3);
}

.result-action-button.secondary {
  background: #f1f5f9;
  color: #475569;
}

.result-action-button.secondary:hover {
  background: #e2e8f0;
}

@media (max-width: 640px) {
  .results-actions {
    flex-direction: column;
  }
  
  .result-action-button {
    width: 100%;
  }
  
  .submit-button {
    width: 100%;
  }
}

/* Animasyon tanımlamaları */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.results-summary {
  animation: fadeIn 0.3s ease-out;
}