@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Fira+Code:wght@400;500&display=swap');

/* Theme Variables */
:root {
  /* Light Theme Colors */
  --ubiAI-background-light: #ffffff;
  --ubiAI-text-light: #333333;
  --ubiAI-border-light: #e9ecef;
  --ubiAI-card-bg-light: #ffffff;
  --ubiAI-hover-light: rgba(251, 133, 0, 0.05);
  --ubiAI-secondary-text-light: #666666;
  
  /* Dark Theme Colors */
  --ubiAI-background-dark: #1a1a1a;
  --ubiAI-text-dark: #ffffff;
  --ubiAI-border-dark: #333333;
  --ubiAI-card-bg-dark: #242424;
  --ubiAI-hover-dark: rgba(251, 133, 0, 0.1);
  --ubiAI-secondary-text-dark: #cccccc;

  /* Common Colors */
  --ubiAI-primary: #fb8500;
  --ubiAI-primary-hover: #e67a00;

  /* Code Theme Colors (Tokyo Night) */
  --code-bg: #1a1b26;
  --code-header: #1f2335;
  --code-border: #2f3241;
  --code-text: #c0caf5;
  --code-accent: #7982a9;
  --code-button-hover: #414868;
}

/* Base Theme Class */
.theme-light {
  --ubiAI-background: var(--ubiAI-background-light);
  --ubiAI-text: var(--ubiAI-text-light);
  --ubiAI-border: var(--ubiAI-border-light);
  --ubiAI-card-bg: var(--ubiAI-card-bg-light);
  --ubiAI-hover: var(--ubiAI-hover-light);
  --ubiAI-secondary-text: var(--ubiAI-secondary-text-light);
}

.theme-dark {
  --ubiAI-background: var(--ubiAI-background-dark);
  --ubiAI-text: var(--ubiAI-text-dark);
  --ubiAI-border: var(--ubiAI-border-dark);
  --ubiAI-card-bg: var(--ubiAI-card-bg-dark);
  --ubiAI-hover: var(--ubiAI-hover-dark);
  --ubiAI-secondary-text: var(--ubiAI-secondary-text-dark);
}

/* Base Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--ubiAI-background);
  color: var(--ubiAI-text);
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* App Container */
.ubiAI-app-container {
  display: flex;
  min-height: 100vh;
  width: 100%;
  position: relative;
  background-color: var(--ubiAI-background);
}

/* Main Content */
.ubiAI-main-content {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  transition: all 0.3s ease;
  margin-left: 0;
}

.ubiAI-main-content.with-sidebar {
  margin-left: 320px;
}

/* Chat Container */
.ubiAI-chat-container {
  width: 100%;
  max-width: 1200px;
  padding: 0 2rem;
  color: var(--ubiAI-text);
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
}

.ubiAI-main-content:not(.with-sidebar) .ubiAI-chat-container {
  margin: 0 auto;
}

/* Content Wrapper */
.ubiAI-content-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: calc(100vh - 2rem);
  position: relative;
}

/* Theme Switcher */
.ubiAI-theme-switcher {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1001;
  background: var(--ubiAI-card-bg);
  border: 1px solid var(--ubiAI-border);
  border-radius: 8px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--ubiAI-text);
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.ubiAI-theme-switcher:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Greeting Section */
.ubiAI-greeting-section {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 10;
  padding: 2rem;
  transition: all 0.5s ease;
  background-color: var(--ubiAI-background);
}

.ubiAI-greeting-section .ubiAI-typewriter-text {
  font-size: 2.5rem;
  font-weight: 600;
  color: var(--ubiAI-text);
  display: inline-block;
}

.ubiAI-with-messages {
  position: static;
  transform: none;
  padding: 1.5rem;
  background: var(--ubiAI-card-bg);
  border-bottom: 1px solid var(--ubiAI-border);
  text-align: left;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.ubiAI-with-messages .ubiAI-typewriter-text {
  font-size: 1.75rem;
}

/* Messages Section */
.ubiAI-messages-section {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 2rem 1rem;
  overflow-y: auto;
  flex: 1;
  margin-top: 3.5rem;
}

/* Message Bubbles */
.ubiAI-message-bubble {
  max-width: 90%;
  border-radius: 1rem;
  overflow: hidden;
  animation: ubiAI-slideIn 0.3s ease-out;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background: var(--ubiAI-card-bg);
  border: 1px solid var(--ubiAI-border);
}

.ubiAI-user-message {
  margin-left: auto;
  border-top-right-radius: 0;
}

.ubiAI-assistant-message {
  margin-right: auto;
  border-top-left-radius: 0;
}

/* Message Content */
.ubiAI-message-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1rem;
  background: var(--ubiAI-hover);
  border-bottom: 1px solid var(--ubiAI-border);
}

.ubiAI-user-info {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.ubiAI-user-avatar, .ubiAI-assistant-avatar {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 0.875rem;
}

.ubiAI-user-avatar {
  background: var(--ubiAI-card-bg);
  color: var(--ubiAI-text);
  border: 1px solid var(--ubiAI-border);
}

.ubiAI-assistant-avatar {
  background: var(--ubiAI-primary);
  color: white;
}

.ubiAI-user-name, .ubiAI-assistant-name {
  font-weight: 500;
  font-size: 0.875rem;
  color: var(--ubiAI-text);
}

.ubiAI-message-content {
  padding: 1rem;
}

.ubiAI-message-text {
  color: var(--ubiAI-text);
  line-height: 1.6;
  font-size: 0.9375rem;
  white-space: pre-wrap;
}
/* Activity Response */
.ubiAI-activity-response {
  background: var(--ubiAI-card-bg);
  border-radius: 0.75rem;
  overflow: hidden;
  border: 1px solid var(--ubiAI-border);
}

.ubiAI-activity-header {
  padding: 1.5rem;
  background: var(--ubiAI-hover);
  border-bottom: 1px solid var(--ubiAI-border);
}

.ubiAI-activity-title {
  color: var(--ubiAI-primary);
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.ubiAI-activity-description {
  color: var(--ubiAI-text);
  font-size: 0.9375rem;
  line-height: 1.6;
  margin-top: 0.5rem;
}

/* Selected Activity Styles */
.ubiAI-selected-activity {
  background: var(--ubiAI-card-bg);
  border: 1px solid var(--ubiAI-border);
  border-radius: 0.75rem;
  margin-bottom: 1rem;
  transition: all 0.2s ease;
  animation: ubiAI-slideIn 0.3s ease-out;
}

.ubiAI-selected-activity-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.875rem 1rem;
  border-radius: 0.75rem;
  background: var(--ubiAI-hover);
}

.ubiAI-selected-activity-info {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: var(--ubiAI-text);
}

.ubiAI-selected-activity-info svg {
  color: var(--ubiAI-primary);
}

.ubiAI-selected-activity-info span {
  font-size: 0.875rem;
  font-weight: 500;
}

.ubiAI-remove-button {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 0.75rem;
  color: #ef4444;
  background: rgba(239, 68, 68, 0.1);
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.ubiAI-remove-button:hover {
  background: rgba(239, 68, 68, 0.15);
  transform: translateY(-1px);
}

.ubiAI-remove-button:active {
  transform: translateY(0);
}

/* Steps Section */
.ubiAI-steps-section {
  padding: 1.5rem;
}

.ubiAI-steps-section h3 {
  color: var(--ubiAI-text);
  font-size: 1.125rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

.ubiAI-steps-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* Step Card */
.ubiAI-step-card {
  background: var(--ubiAI-card-bg);
  border-radius: 0.75rem;
  overflow: hidden;
  border: 1px solid var(--ubiAI-border);
  transition: all 0.2s ease;
}

.ubiAI-step-card:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.ubiAI-step-header {
  width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--ubiAI-card-bg);
  border: none;
  cursor: pointer;
  color: var(--ubiAI-text);
  transition: background-color 0.2s;
}

.ubiAI-step-header:hover {
  background: var(--ubiAI-hover);
}

.ubiAI-step-title {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.ubiAI-step-number {
  width: 24px;
  height: 24px;
  background: var(--ubiAI-primary);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 0.875rem;
  font-weight: 600;
}

/* Step Content */
.ubiAI-step-content {
  padding: 1rem;
  border-top: 1px solid var(--ubiAI-border);
  animation: ubiAI-slideDown 0.3s ease-out;
}

.ubiAI-step-description {
  color: var(--ubiAI-secondary-text);
  font-size: 0.9375rem;
  line-height: 1.6;
  margin-bottom: 1rem;
}

/* Code Container Styles */
.ubiAI-code-container {
  background: var(--code-bg);
  border-radius: 0.75rem;
  overflow: hidden;
  border: 1px solid var(--code-border);
  margin-top: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.ubiAI-code-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1rem;
  background: var(--code-header);
  border-bottom: 1px solid var(--code-border);
  position: relative;
}

/* Terminal-style buttons */
.ubiAI-code-header::before {
  content: '';
  position: absolute;
  left: 1rem;
  width: 12px;
  height: 12px;
  background: #ff5f56;
  border-radius: 50%;
  box-shadow: 
    20px 0 0 #ffbd2e,
    40px 0 0 #27c93f;
}

.ubiAI-code-header span {
  margin-left: 4rem;
  color: var(--code-accent);
  font-family: 'Fira Code', monospace;
  font-size: 0.875rem;
}

/* Code Block */
.ubiAI-code-block {
  margin: 0;
  padding: 1.25rem;
  font-family: 'Fira Code', monospace;
  font-size: 0.875rem;
  line-height: 1.7;
  color: var(--code-text);
  background: var(--code-bg);
  overflow-x: auto;
  white-space: pre;
}

/* Syntax Highlighting */
.ubiAI-code-block .keyword { color: #bb9af7; }
.ubiAI-code-block .string { color: #9ece6a; }
.ubiAI-code-block .number { color: #ff9e64; }
.ubiAI-code-block .function { color: #7aa2f7; }
.ubiAI-code-block .comment { color: #565f89; font-style: italic; }
.ubiAI-code-block .class { color: #2ac3de; }
.ubiAI-code-block .operator { color: #89ddff; }
.ubiAI-code-block .variable { color: #e0af68; }

/* Copy Button in Code Header */
.ubiAI-code-header .ubiAI-copy-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.375rem 0.75rem;
  background: var(--code-border);
  border: 1px solid var(--code-button-hover);
  border-radius: 0.375rem;
  color: var(--code-accent);
  font-size: 0.75rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.ubiAI-code-header .ubiAI-copy-button:hover {
  background: var(--code-button-hover);
  color: var(--code-text);
  transform: translateY(-1px);
}

.ubiAI-code-header .ubiAI-copy-button:active {
  transform: translateY(0);
}

.ubiAI-code-header .ubiAI-copy-button span {
  margin-left: 0;
  color: inherit;
}

/* Code Block Scrollbar */
.ubiAI-code-block::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}

.ubiAI-code-block::-webkit-scrollbar-track {
  background: var(--code-bg);
}

.ubiAI-code-block::-webkit-scrollbar-thumb {
  background: var(--code-button-hover);
  border-radius: 3px;
}

.ubiAI-code-block::-webkit-scrollbar-thumb:hover {
  background: var(--code-accent);
}

/* Input Section */
.ubiAI-input-footer {
  position: sticky;
  bottom: 0;
  background: var(--ubiAI-background);
  padding: 1rem 2rem;
  border-top: 1px solid var(--ubiAI-border);
  margin-top: auto;
  z-index: 100;
  width: 100%;
}

.ubiAI-input-wrapper {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.ubiAI-input-section {
  display: flex;
  align-items: flex-end;
  background: var(--ubiAI-card-bg);
  border: 1px solid var(--ubiAI-border);
  border-radius: 1rem;
  transition: all 0.2s ease;
}

.ubiAI-input-section:focus-within {
  border-color: var(--ubiAI-primary);
  box-shadow: 0 0 0 2px rgba(251, 133, 0, 0.1);
}

.ubiAI-chat-input {
  flex: 1;
  min-height: 52px;
  max-height: 200px;
  padding: 1rem;
  padding-right: 3rem;
  background: transparent;
  border: none;
  color: var(--ubiAI-text);
  font-size: 1rem;
  resize: none;
  line-height: 1.5;
}

.ubiAI-chat-input:focus {
  outline: none;
}

.ubiAI-chat-input::placeholder {
  color: var(--ubiAI-secondary-text);
}

/* Submit Button */
.ubiAI-submit-button {
  position: absolute;
  right: 0.75rem;
  bottom: 0.75rem;
  background: var(--ubiAI-primary);
  border: none;
  border-radius: 0.5rem;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  transition: all 0.2s;
}

.ubiAI-submit-button:hover:not(:disabled) {
  background: var(--ubiAI-primary-hover);
  transform: scale(1.05);
}

.ubiAI-submit-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Quick Suggestions */
.ubiAI-quick-actions {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1rem;
}

.ubiAI-quick-suggestion {
  background: var(--ubiAI-card-bg);
  border: 1px solid var(--ubiAI-primary);
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  color: var(--ubiAI-primary);
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s;
}

.ubiAI-quick-suggestion:hover {
  background: var(--ubiAI-primary);
  color: #fff;
  transform: translateY(-1px);
}

/* Loading Spinner */
.ubiAI-loading-spinner {
  width: 20px;
  height: 20px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  border-top-color: transparent;
  animation: ubiAI-spin 0.8s linear infinite;
}

/* Disclaimer */
.ubiAI-disclaimer {
  text-align: center;
  color: var(--ubiAI-secondary-text);
  font-size: 0.75rem;
  margin-top: 1rem;
}

/* Error Message */
.ubiAI-error-message {
  padding: 1rem;
  background: #fee2e2;
  border: 1px solid #fecaca;
  border-radius: 0.5rem;
  color: #dc2626;
}

/* Full Code Section */
.ubiAI-full-code-section {
  padding: 1.5rem;
  border-top: 1px solid var(--code-border);
}

.ubiAI-full-code-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.ubiAI-full-code-header h3 {
  color: var(--ubiAI-text);
  font-size: 1.125rem;
  font-weight: 600;
}

/* Suggestions Header */
.ubiAI-suggestions-header {
  color: var(--ubiAI-text);
  font-size: 1.125rem;
  font-weight: 600;
  margin: 1.5rem 0 1rem;
  padding: 0 1rem;
}

/* Animations */
@keyframes ubiAI-slideIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes ubiAI-slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes ubiAI-spin {
  to {
    transform: rotate(360deg);
  }
}

/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: var(--ubiAI-background);
}

::-webkit-scrollbar-thumb {
  background: var(--ubiAI-secondary-text);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--ubiAI-text);
}

/* Light and Dark theme consistency for code blocks */
.theme-light .ubiAI-code-container,
.theme-dark .ubiAI-code-container {
  background: var(--code-bg);
  border-color: var(--code-border);
}

.theme-light .ubiAI-code-header,
.theme-dark .ubiAI-code-header {
  background: var(--code-header);
  border-color: var(--code-border);
}

.theme-light .ubiAI-code-block,
.theme-dark .ubiAI-code-block {
  background: var(--code-bg);
  color: var(--code-text);
}

/* Responsive Design */
@media (min-width: 1440px) {
  .ubiAI-chat-container {
    max-width: 1400px;
  }
  
  .ubiAI-main-content.with-sidebar .ubiAI-chat-container {
    max-width: 1200px;
  }

  .ubiAI-greeting-section .ubiAI-typewriter-text {
    font-size: 3rem;
  }

  .ubiAI-with-messages .ubiAI-typewriter-text {
    font-size: 2rem;
  }

  .ubiAI-message-bubble {
    max-width: 80%;
  }

  .ubiAI-code-block {
    font-size: 1rem;
  }

  .ubiAI-chat-input {
    font-size: 1.125rem;
  }
}

@media (max-width: 1200px) {
  .ubiAI-chat-container {
    max-width: 1000px;
  }
  
  .ubiAI-main-content.with-sidebar .ubiAI-chat-container {
    max-width: 900px;
  }
}

@media (max-width: 1024px) {
  .ubiAI-main-content.with-sidebar {
    margin-left: 280px;
  }

  .ubiAI-chat-container {
    padding: 0 1.5rem;
  }

  .ubiAI-main-content.with-sidebar .ubiAI-chat-container {
    max-width: 800px;
  }
}

@media (max-width: 768px) {
  .ubiAI-main-content.with-sidebar {
    margin-left: 260px;
  }

  .ubiAI-chat-container {
    padding: 0 1rem;
  }

  .ubiAI-input-footer {
    padding: 1rem;
  }

  .ubiAI-greeting-section {
    padding: 1.5rem 1rem;
  }

  .ubiAI-greeting-section .ubiAI-typewriter-text {
    font-size: 2rem;
  }

  .ubiAI-with-messages .ubiAI-typewriter-text {
    font-size: 1.5rem;
  }

  .ubiAI-message-bubble {
    max-width: 95%;
  }

  .ubiAI-activity-header,
  .ubiAI-steps-section,
  .ubiAI-full-code-section {
    padding: 1rem;
  }

  .ubiAI-quick-actions {
    flex-direction: column;
    padding: 0 0.5rem;
  }

  .ubiAI-quick-suggestion {
    width: 100%;
    text-align: center;
  }

  .ubiAI-code-container {
    margin: 0.5rem 0;
  }

  .ubiAI-code-header {
    padding: 0.5rem 0.75rem;
  }

  .ubiAI-code-block {
    padding: 1rem 0.75rem;
    font-size: 0.8125rem;
  }

  .ubiAI-step-header {
    padding: 0.75rem;
  }

  .ubiAI-step-content {
    padding: 0.75rem;
  }

  .ubiAI-step-description {
    font-size: 0.875rem;
  }
}

@media (max-width: 480px) {
  .ubiAI-main-content.with-sidebar {
    margin-left: 240px;
  }

  .ubiAI-chat-container {
    padding: 0 0.5rem;
  }

  .ubiAI-greeting-section .ubiAI-typewriter-text {
    font-size: 1.75rem;
  }

  .ubiAI-with-messages .ubiAI-typewriter-text {
    font-size: 1.25rem;
  }

  .ubiAI-message-bubble {
    max-width: 100%;
  }

  .ubiAI-user-avatar, .ubiAI-assistant-avatar {
    width: 24px;
    height: 24px;
    font-size: 0.75rem;
  }

  .ubiAI-copy-button {
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
  }

  .ubiAI-code-header::before {
    display: none;
  }
  
  .ubiAI-code-header span {
    margin-left: 0;
  }
  
  .ubiAI-code-block {
    font-size: 0.75rem;
    padding: 0.875rem;
  }

  .ubiAI-theme-switcher {
    width: 36px;
    height: 36px;
    top: 12px;
    right: 12px;
  }

  .ubiAI-disclaimer {
    font-size: 0.6875rem;
  }

  .ubiAI-input-footer {
    padding: 0.75rem;
  }

  .ubiAI-selected-activity-info span {
    font-size: 0.813rem;
  }
}

/* Print styles */
@media print {
  .ubiAI-app-container {
    display: block;
    background: white;
  }

  .ubiAI-main-content {
    margin-left: 0 !important;
  }

  .ubiAI-chat-container {
    max-width: 100% !important;
  }

  .ubiAI-theme-switcher,
  .ubiAI-input-footer,
  .ubiAI-quick-actions,
  .ubiAI-copy-button,
  .ubiAI-submit-button {
    display: none;
  }

  .ubiAI-message-bubble {
    break-inside: avoid;
    box-shadow: none;
  }

  .ubiAI-code-block {
    white-space: pre-wrap;
    border: 1px solid #ccc;
  }
}