/* Landing.css */
.ubicraft-landing-wrapper {
    position: relative;
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
  }
  
  .ubicraft-landing-container {
    position: relative;
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .ubicraft-landing-container > * {
    width: 100%;
    max-width: 100vw;
    margin: 0;
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
  }
  
  .ubicraft-landing-main {
    width: 100%;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .ubicraft-landing-main > * {
    width: 100%;
    max-width: 100%;
    margin: 0;
    box-sizing: border-box;
  }
  
  .ubicraft-landing-registration {
    width: 100%;
    max-width: 100%;
  }
  
  /* Mobil görünüm için özel stiller */
  @media screen and (max-width: 768px) {
    .ubicraft-landing-wrapper {
      width: 100vw;
      margin: 0;
      padding: 0;
      touch-action: pan-y pinch-zoom;
    }
    
    .ubicraft-landing-container {
      width: 100%;
      padding: 0;
      margin: 0;
    }
    
    .ubicraft-landing-main {
      width: 100%;
      overflow: hidden;
    }
    
    .ubicraft-landing-main > * {
      padding-left: env(safe-area-inset-left);
      padding-right: env(safe-area-inset-right);
    }
    
    /* iPhone ve diğer mobil cihazlar için notch alanı düzeltmesi */
    @supports (padding: max(0px)) {
      .ubicraft-landing-container {
        padding-left: max(0px, env(safe-area-inset-left));
        padding-right: max(0px, env(safe-area-inset-right));
      }
    }
  }
  
  /* Tablet görünümü için özel stiller */
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .ubicraft-landing-wrapper {
      width: 100%;
    }
    
    .ubicraft-landing-main > * {
      max-width: 100%;
    }
  }