.home-container {
  padding: 20px;
  max-width: 1300px;
  margin: 0 auto;
  font-family: 'Poppins', sans-serif;
}

.home-track-card {
  background-color: #301934;
  color: white;
  border-radius: 16px;
  padding: 30px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  transition: all 0.3s ease;
}

.home-track-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
}

.home-track-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.home-track-title {
  font-size: 14px;
  color: #e5e4e2;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 10px;
  font-weight: 500;
}

.home-track-header h1 {
  font-size: 32px;
  margin: 10px 0;
  font-weight: 700;
}

.home-track-description {
  font-size: 18px;
  opacity: 0.8;
}

.home-track-info {
  display: flex;
  flex-wrap: wrap;
  margin: 30px 0;
}

.home-progress-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 30px;
}

.circular-progress {
  width: 120px;
  height: 120px;
  flex-shrink: 0;
}

.next-lesson-box {
  background-color: #fce0a2;
  border-radius: 12px;
  padding: 20px;
  flex-grow: 0.6;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
}

.next-lesson-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/* Increase specificity */
.home-track-card .next-lesson-box .lesson-infom {
  display: flex;
  flex-direction: column;
}

.home-track-card .next-lesson-box .lesson-infom h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #301934;
}

.home-track-card .next-lesson-box .lesson-infom p {
  font-size: 16px;
  color: #fb8500;
  font-weight: 600;
}

.next-lesson-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(251, 133, 0, 0.2);
}

.next-lesson-box h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #301934;
}

.next-lesson-box p {
  font-size: 16px;
  margin-bottom: 15px;
  color: #fff;
}

.start-lesson-button {
  background-color: #301934;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 30px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.3s ease;
}

.start-lesson-button:hover {
  background-color: #4A2C5A;
  transform: translateY(-2px);
}
.home-progress-details {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-shrink: 0;
}


.home-progress-details {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.home-progress-button {
  background-color: #fb8500;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 30px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  margin-top: 15px;
}

.home-progress-button:hover {
  background-color: #ffa238;
  transform: translateY(-2px);
}

.home-course-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-top: 1px solid rgba(251, 133, 0, 0.3);
  padding-top: 30px;
  margin-top: 30px;
}

.home-course-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: 0 15px;
}

.home-section-header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.home-section-title {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.home-section-body {
  font-size: 16px;
  text-align: center;
  opacity: 0.9;
}

.replit-container {
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
  gap: 20px;
}

.replit-card {
  background-color: #301934;
  color: white;
  padding: 25px;
  border-radius: 16px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.3s ease;
}

.replit-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
}

.replit-header {
  display: flex;
  align-items: center;
  gap: 20px;
}

.replit-icon {
  width: 60px;
  height: 60px;
  object-fit: contain;
}

.replit-content {
  background-color: rgba(80, 45, 86, 0.5);
  border-radius: 12px;
  padding: 20px;
  margin-top: 20px;
}

.replit-title {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 10px;
}

.replit-description {
  font-size: 16px;
  color: #e5e4e2;
  opacity: 0.9;
}

.replit-button {
  background-color: #fb8500;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 30px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  display: block;
  margin-top: 25px;
  transition: all 0.3s ease;
  text-align: center;
  width: 100%;
}

.replit-button:hover {
  background-color: #ffa238;
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .home-course-footer {
    flex-wrap: wrap;
  }

  .home-course-section {
    flex-basis: 50%;
    margin-bottom: 20px;
  }

  .replit-container {
    flex-direction: column;
  }

  .replit-card {
    margin-bottom: 20px;
  }
}

@media (max-width: 480px) {
  .home-course-section {
    flex-basis: 100%;
  }

  .home-progress-container {
    flex-direction: column;
    align-items: center;
  }

  .next-lesson-box {
    width: 100%;
    margin: 20px 0;
  }

  .home-progress-details {
    align-items: center;
  }


  .home-progress-details {
    align-items: center;
    margin-top: 20px;
  }
}