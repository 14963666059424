/* Ana stil */
.hero-background-custom {
  background-color: #FCE0A2;
  padding: 20px;
  height: calc(100vh);
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1; /* Arka plan şekillerinin önünde, diğer bileşenlerin arkasında */
  position: relative; /* Z-index'in çalışması için relative ekliyoruz */
  
  font-family: Poppins, sans-serif;
}
@keyframes slideInFromLeftCustom {
  0% {
    transform: translateX(-20%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRightCustom {
  0% {
    transform: translateX(30%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.image-container-custom {
  position: relative;
  width: 40%;
  margin-right: 0%;
  animation: slideInFromLeftCustom 0.5s ease-out forwards;
}

.image-container-custom .laptop-image-custom {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-left: -10%;
  margin-top: 0%;
  animation: slideInFromRightCustom 0.5s ease-out forwards;
  z-index: 1;
}
/* Arka plan için kullanılan simgelerin konum ve açılarının rastgele dağılması */
/* Arka plan şekillerinin stilini düzenliyoruz */
.icon-patterns {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Arka plan şekillerini diğer bileşenlerin arkasına, arka planın önüne yerleştiriyoruz */
}

.pattern-icons {
  position: absolute;
  font-size: 80px;
  color: rgba(0, 0, 0, 0.05);  /* İkonlar arka planda soluk görünsün */
  animation: float 8s infinite;
}

/* Rastgele dağıtılan simgeler */
.pattern-icons:nth-child(1) {
  top: 10%;
  left: 5%;
  transform: rotate(15deg);
  color: #78caf3;
}

.pattern-icons:nth-child(2) {
  top: 20%;
  left: 40%;
  transform: rotate(-30deg);
  color: #58dd91;
}

.pattern-icons:nth-child(3) {
  top: 65%;
  left: 25%;
  transform: rotate(45deg);
  color: #ff7283;
}

.pattern-icons:nth-child(4) {
  top: 65%;
  left: 45%;
  transform: rotate(-20deg);
  color: #fb8500;
}



/* İkonların hafifçe yukarı ve aşağı yüzen bir efektle hareket etmesi */
@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
}
}

/* Diğer mevcut CSS kurallarına dokunmadan eklemeler yapıldı */

/* Tablet ve orta boyutlu cihazlar için stil */
@media (max-width: 1024px) {
  .hero-background-custom {
    text-align: left;
    padding-top: 40px;
  }

  .text-left-custom {
    display: flex;
    flex-direction: column;
  }

  .text-left-custom h1 {
    font-size: 3rem;
    min-height: 3rem;
  }

  .animated-text-container,
  .animated-text-placeholder {
    font-size: 3rem;
    margin-left: 0;
    min-height: 40px;
    font-weight: bold;
  }

  .text-left-custom p {
    font-size: 1.5rem;
    min-height: 3rem;
    margin-bottom: 20px;
  }

  .deneme-dersi-button-custom {
    font-size: 1.2rem;
    padding: 14px 28px;
    border: 2px solid #fb8500;
    transition: all 0.3s ease;
    margin-top: 40px;
  }

  .deneme-dersi-button-custom:hover {
    background-color: white;
    color: #fb8500;
  }

  .image-container-custom {
    display: none !important;
  }

  .hero-background-custom {
    padding: 10px;
  }
}

/* Mobil cihazlar için stil */
@media (max-width: 468px) {
  .hero-background-custom {
    text-align: left;
    padding-top: 40px;
    height: 62vh;
  }

  .text-left-custom {
    display: flex;
    flex-direction: column;
    margin-bottom: 350px;
  }

  .text-left-custom h1 {
    font-size: 2rem;
    margin-bottom: 10px;
  }

  .animated-text-container,
  .animated-text-placeholder {
    font-size: 2rem;
    font-weight: bold;
    margin-left: 0;
    min-height: 40px;
  }

  .text-left-custom p {
    font-size: 1.1rem;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .deneme-dersi-button-custom {
    font-size: 1rem;
    padding: 8px 12px;
    border: 2px solid #fb8500;
    transition: all 0.3s ease;
    margin-top: 40px;
  }

  .deneme-dersi-button-custom:hover {
    background-color: white;
    color: #fb8500;
  }

  .image-container-custom {
    display: none !important;
  }
  .pattern-icons:nth-child(1) {
    top: 10%;
    left: 70%;
    transform: rotate(15deg);
    color: #78caf3;
    scale: 0.5
  }

  .pattern-icons:nth-child(2) {
    top: 3%;
    left: 5%;
    transform: rotate(-30deg);
    color: #58dd91;
    scale: 0.5
  }

  .pattern-icons:nth-child(3) {
    top: 30%;
    left: 55%;
    transform: rotate(45deg);
    color: #ff7283;
    scale: 0.5
  }

  .pattern-icons:nth-child(4) {
    top: 70%;
    left: 70%;
    transform: rotate(-20deg);
    color: #fb8500;
    scale: 0.5
  }

  .pattern-icons:nth-child(5) {
    top: 35%;
    left: 45%;
    transform: rotate(10deg);
    color: #ffe054;
    scale: 0.5;
  }
}


/* Büyük ekranlar için stil */
@media (min-width: 1025px) {
  .hero-background-custom {
    text-align: left;

  }

  .text-left-custom h1 {
    font-size: 3.5rem;
  }

  .animated-text-container,
  .animated-text-placeholder {
    font-size: 3.5rem;
    font-weight: bold;
  }

  .text-left-custom p {
    margin-bottom: 20px;
  }

  .deneme-dersi-button-custom {
    padding: 16px 32px;
    border: 2px solid #fb8500;
    transition: all 0.3s ease;
    margin-top: 40px;
  }

  .deneme-dersi-button-custom:hover {
    background-color: white;
    color: #fb8500;
  }

  .image-container-custom {
    width: 50%;
  }

  .image-container-custom .laptop-image-custom {
    margin-left: 0%;
  }
}
