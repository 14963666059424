.attendance-page {
  font-family: 'Poppins', sans-serif;
  max-width: 1150px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.attendance-title {
  font-size: 28px;
  font-weight: 600;
  color: #333333;
  text-align: center;
  margin-bottom: 20px;
}

.attendance-info {
  text-align: center;
  margin-bottom: 20px;
}

.info-box {
  display: flex;
  justify-content: center;
  gap: 20px;
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 8px;
}

.info-item {
  display: flex;
  align-items: center;
}

.color-box {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-radius: 4px;
}

.color-box.green { background-color: #58DD91; }
.color-box.red { background-color: #FF7283; }
.color-box.yellow { background-color: #FFE054; }

.attendance-card {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.name-label {
  color: #FB8500;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 16px;
}

.weeks-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.module-row {
  display: flex;
  margin-bottom: 5px;
}

.module-label {
  width: 180px;
  text-align: center;
  font-weight: bold;
  color: #FB8500;
  margin: 0 10px;
  padding: 5px 0;
  border: 1px solid #FB8500;
  border-radius: 6px;
  background-color: white;
}

.week-row {
  display: flex;
}

.week2-container {
  position: relative;
  margin: 5px;
}

.week-box {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  transition: all 0.3s ease;
}

.week-box:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.week-box.grey { background-color: #e0e0e0; }
.week-box.yellow { background-color: #FFE054; }
.week-box.red { background-color: #FF7283; }
.week-box.green { background-color: #58DD91; }

.week-number {
  font-size: 12px;
  font-weight: bold;
  color: #333333;
}