@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

.refinedEduPlatform-container {
  font-family: 'Poppins', sans-serif;
  min-height: 100vh;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.refinedEduPlatform-content {
  max-width: 1200px;
  width: 100%;
}

.refinedEduPlatform-title {
  font-size: 2.5rem;
  color: #301934;
  text-align: center;
  font-weight: 500;
  margin-bottom: 2rem;
}

.refinedEduPlatform-selector {
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
}

.refinedEduPlatform-selectorBtn {
  background: #f0f0f0;
  border: none;
  color: #301934;
  padding: 1rem 2rem;
  margin: 0 1rem;
  font-size: 1.1rem;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  font-weight: 500;
}

.refinedEduPlatform-selectorBtn svg {
  margin-right: 0.5rem;
}

.refinedEduPlatform-selectorBtn.active,
.refinedEduPlatform-selectorBtn:hover {
  background: linear-gradient(135deg, #f6d365 0%, #fda085 100%);
  color: #ffffff;
}

.refinedEduPlatform-contentArea {
  display: flex;
  align-items: flex-start;
  gap: 4rem;
}

.refinedEduPlatform-imageContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.refinedEduPlatform-image {
  width: 160%;
  max-width: 1500px;
  height: auto;
  object-fit: contain;
}

.refinedEduPlatform-featuresContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.refinedEduPlatform-featureCard {
  background: rgba(253, 160, 133, 0.1);
  border-radius: 15px;
  padding: 1.5rem;
  display: flex;
  align-items: flex-start;
  transition: all 0.3s ease;
}

.refinedEduPlatform-featureCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(253, 160, 133, 0.2);
}

.refinedEduPlatform-featureIcon {
  font-size: 2rem;
  color: #fdc185;
  margin-right: 1rem;
}

.refinedEduPlatform-featureText h3 {
  color: #301934;
  margin-bottom: 0.5rem;
}

.refinedEduPlatform-featureText p {
  color: #4a4a4a;
  font-size: 0.9rem;
}

@media (max-width: 968px) {
  .refinedEduPlatform-contentArea {
    flex-direction: column;
  }
  
  .refinedEduPlatform-imageContainer {
    max-width: 150%;
  }

  .refinedEduPlatform-featuresContainer {
    width: 100%;
  }

  .refinedEduPlatform-image {
    max-width: 200%;
  }

  .refinedEduPlatform-selectorBtn {
    font-size: 0.9rem;
    padding: 0.8rem 1.5rem;
  }
}
