/* consentPopup.css */

.consent-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Yarı saydam arka plan */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .consent-popup-content {
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 10px;
    max-width: 800px;
    width: 90%;
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .consent-popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .consent-popup-header h2 {
    color: #301934;
    font-size: 1.8rem;
    font-weight: 700;
  }
  
  .consent-close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .consent-popup-body h3 {
    color: #301934;
    font-size: 1.4rem;
    font-weight: 700;
    margin-top: 1rem;
  }
  
  .consent-popup-body p,
  .consent-popup-body ul {
    color: #4a4a4a;
    line-height: 1.6;
    margin-bottom: 1rem;
  }
  
  .consent-popup-body ul {
    padding-left: 1.5rem;
  }
  
  .consent-popup-body li {
    margin-bottom: 0.5rem;
  }
  
  /* Responsive tasarım */
  @media (max-width: 768px) {
    .consent-popup-content {
      padding: 1rem;
    }
  
    .consent-popup-header h2 {
      font-size: 1.5rem;
    }
  
    .consent-popup-body h3 {
      font-size: 1.2rem;
    }
  }
  