.enhanced-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .enhanced-popup-content {
    background-color: #fff;
    border-radius: 12px;
    padding: 2.5rem;
    max-width: 1100px;
    width: 90%;
    max-height: 90vh;
    overflow-y: auto;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  .enhanced-popup-body {
    display: flex;
    gap: 3rem;
  }
  
  .enhanced-popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    padding-bottom: 1.5rem;
    border-bottom: 2px solid #eee;
  }
  
  .enhanced-popup-header h2 {
    font-size: 2rem;
    font-weight: 700;
    color: #333;
  }
  
  .enhanced-close-button {
    background: none;
    border: none;
    font-size: 1.75rem;
    cursor: pointer;
    color: #777;
    transition: color 0.3s ease;
  }
  
  .enhanced-close-button:hover {
    color: #222;
  }
  
  .enhanced-payment-info,
  .enhanced-parent-info {
    flex: 1;
  }
  
  .enhanced-payment-info h3,
  .enhanced-parent-info h3 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 1rem;
  }
  
  .enhanced-plan-details {
    font-size: 1.3rem;
    color: #FB8500;
    margin-bottom: 2rem;
  }
  
  .enhanced-payment-info ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .enhanced-payment-info li {
    position: relative;
    padding-left: 1.5rem;
    margin-bottom: 1rem;
    font-size: 1.1rem;
    color: #555;
  }
  
  .enhanced-payment-info li::before {
    content: '•';
    position: absolute;
    left: 0;
    color: #FB8500;
    font-size: 1.5rem;
    line-height: 1;
  }
  
  .enhanced-form-group {
    margin-bottom: 2rem;
  }
  
  label {
    display: flex;
    align-items: center;
    margin-bottom: 0.75rem;
    font-weight: 600;
    color: #333;
  }
  
  .enhanced-form-icon {
    margin-right: 0.75rem;
    color: #FB8500;
    font-size: 1.25rem;
  }
  
  input,
  textarea {
    width: 100%;
    padding: 1rem;
    border: 2px solid #ddd;
    border-radius: 8px;
    font-size: 1.1rem;
    transition: border-color 0.3s ease;
  }
  
  input:focus,
  textarea:focus {
    outline: none;
    border-color: #FB8500;
  }
  
  button[type='submit'] {
    display: flex;
    align-items: center;
    padding: 1rem 2rem;
    background-color: #FB8500;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1.1rem;
    font-weight: 600;
    transition: background-color 0.3s ease;
  }
  
  button[type='submit']:hover {
    background-color: #F97316;
  }
  
  button[type='submit']:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .enhanced-submit-icon {
    margin-right: 0.75rem;
    font-size: 1.25rem;
  }
  
  .enhanced-form-group label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .enhanced-form-group input[type='checkbox'] {
    margin-right: 0.5rem;
    width: 1.25rem;
    height: 1.25rem;
    transition: border-color 0.3s ease;
  }

  @media screen and (max-width: 768px) {
    .enhanced-popup-content {
      padding: 2rem;
      width: 95%;
    }
  
    .enhanced-popup-body {
      flex-direction: column;
      gap: 2rem;
    }
  
    .enhanced-payment-info,
    .enhanced-parent-info {
      flex: 1;
    }
  
    .enhanced-form-group {
      margin-bottom: 1.5rem;
    }
  }
  