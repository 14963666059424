.creativity-container {
  position: relative;
  padding: 50px;
  font-family: 'Poppins', sans-serif;
  background-color: #f8f9fa;
  overflow: hidden;
  min-height: 100vh; /* Ekranın tamamını kaplaması için */
  display: flex; /* İçeriği ortalamak için flexbox */
  flex-direction: column;
  justify-content: center; /* Dikey ortalama */
  align-items: center; /* Yatay ortalama */
}
  

/* Arka plan için kullanılan simgelerin konum ve açılarının rastgele dağılması */
.icon-pattern {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.pattern-icon {
  position: absolute;
  font-size: 80px;
  color: rgba(0, 0, 0, 0.05);  /* İkonlar arka planda soluk görünsün */
  animation: float 8s infinite;
}

/* Rastgele dağıtılan simgeler */
.pattern-icon:nth-child(1) {
  top: 10%;
  left: 5%;
  transform: rotate(15deg);
}

.pattern-icon:nth-child(2) {
  top: 20%;
  left: 40%;
  transform: rotate(-30deg);
}

.pattern-icon:nth-child(3) {
  top: 50%;
  left: 15%;
  transform: rotate(45deg);
}

.pattern-icon:nth-child(4) {
  top: 35%;
  left: 20%;
  transform: rotate(-20deg);
}

.pattern-icon:nth-child(5) {
  top: 45%;
  left: 55%;
  transform: rotate(10deg);
}

.pattern-icon:nth-child(6) {
  top: 35%;
  left: 70%;
  transform: rotate(-40deg);
}

.pattern-icon:nth-child(7) {
  top: 5%;
  left: 80%;
  transform: rotate(25deg);
}

.pattern-icon:nth-child(8) {
  top: 75%;
  left: 10%;
  transform: rotate(-35deg);
}

.pattern-icon:nth-child(9) {
  top: 30%;
  left: 85%;
  transform: rotate(20deg);
}

.pattern-icon:nth-child(10) {
  top: 80%;
  left: 90%;
  transform: rotate(-25deg);
}

/* İkonların hafifçe yukarı ve aşağı yüzen bir efektle hareket etmesi */
@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
}
}

.header {
  position: relative;
  z-index: 1;
  text-align: center;
  margin-bottom: 60px;
}

.section-title {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 20px;
  color: #301934;
}

.section-subtitle {
  font-size: 1.2rem;
  color: #6c757d;
  max-width: 800px;
  margin: 0 auto;
}

.ubicraft-highlight {
  color: #f5841f;
  position: relative;
  display: inline-block;
}

.ubicraft-highlight::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 4px;
  bottom: -4px;
  left: 0;
  background-color: #f5841f;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.ubicraft-highlight:hover::after {
  transform: scaleX(1);
}

.creativity-cards-container {
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
}

.creativity-card {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 40px;
  width: calc(33.333% - 20px);
  box-sizing: border-box;
  text-align: center;
  position: relative;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  z-index: 1;
}

.creativity-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
}

.creativity-card.active {
  background-color: #f5841f;
  color: #ffffff;
}

.card-content {
  position: relative;
  z-index: 2;
}

.ubicon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -30%);
  opacity: 0.3;
}

.ubicon svg {
  color: #f5841f;
  transition: color 0.3s ease;
}

.text-content {
  position: relative;
}

.card-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 15px;
  color: #301934;
}

.creativity-card.active .card-title {
  color: #ffffff;
}

.card-description {
  font-size: 1rem;
  color: #6c757d;
  line-height: 1.6;
}

.creativity-card.active .card-description {
  color: #ffffff;
}

@media (max-width: 1024px) {
  .creativity-card {
    width: calc(50% - 15px);
  }
}

@media (max-width: 768px) {
  .creativity-container {
    padding: 30px;
  }

  .section-title {
    margin-top: 35px;
    font-size: 1.9rem;
  }

  .section-subtitle {
    font-size: 1rem;
  }

  .creativity-cards-container {
    flex-direction: column;
  }

  .creativity-card {
    width: 100%;
    margin-bottom: 20px;
  }
}
