.buttonCustom {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  color: #ffffff;
  width: auto;
  padding: 0 16px;
  height: 40px;
  margin-top: 12px;
  border-radius: 24px;
  font-weight: bold;
  background-color: #fb8500;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.buttonCustom:hover {
  background-color: #e07a00;
}

.buttonContainer .buttonCustom {
  margin-right: 8px;
}

.tableContainer {
  width: 100%;
  padding: 16px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 8px;
  overflow: hidden;
}

.table thead {
  background-color: #ffffff;
}

.table thead th {
  color: #fb8500;
  background-color: #ffffff;
  padding: 12px;
  text-align: left;
  border-bottom: 2px solid #fb8500;
}

.table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.table tbody tr:last-child {
  border-bottom: none;
}

.table tbody td {
  padding: 12px;
  text-align: left;
}

.table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.buttonBack {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  color: #ffffff;
  width: 112px;
  height: 44px;
  margin-top: 12px;
  border-radius: 24px;
  font-weight: bold;
  background-color: #fb8500;
  padding: 12px;
  border: none;
  cursor: pointer;
}

.selectGrade {
  padding: 8px;
  padding-left: 28px;
  margin-left: 10px;
  border-radius: 10px;
  border: 1px solid #ddd;
  font-family: 'Poppins', sans-serif;
  align-items: center;
  max-width: 125px;
  font-size: 15px;

}

/* Grades Background Colors */
.selectGrade.excellent,
.selectGrade option.excellent {
  background-color: #78caf3;
  color: white;
}

.selectGrade.very-good,
.selectGrade option.very-good {
  background-color: #58dd91;
  color: white;
}

.selectGrade.good,
.selectGrade option.good {
  background-color: #ffe054;
  color: white;
}

.selectGrade.average,
.selectGrade option.average {
  background-color: #fb8500;
  color: white;
}

.selectGrade.poor,
.selectGrade option.poor {
  background-color: #ff7283;
  color: white;
}

.grid {
  display: grid;
}

.gridCols16 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.gap4 {
  gap: 1rem;
}

.mb1 {
  margin-bottom: 0.25rem;
}

.flex {
  display: flex;
}

.itemsCenter {
  align-items: center;
}

.mb4 {
  margin-bottom: 1rem;
}

.mb2 {
  margin-bottom: 0.5rem;
}

.mt3 {
  margin-top: 0.75rem;
}

.mt4 {
  margin-top: 1rem;
}

.mx6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.fontPoppins {
  font-family: 'Poppins', sans-serif;
}

.fontBold {
  font-weight: bold;
}

.text2xl {
  font-size: 1.5rem;
}

.smText3xl {
  font-size: 1.875rem;
}

.textPrimary {
  color: #2b2d42;
}

.textXl {
  font-size: 1.25rem;
}

.textL {
  font-size: 1.125rem;
}

.textWhite {
  color: #ffffff;
}

.bgGray {
  background-color: #D9D9D9;
}

.bgOrange {
  background-color: #fb8500;
}

.rounded3xl {
  border-radius: 1.5rem;
  
}

.wFull {
  width: 100%;
}

.h48 {
  height: 12rem;
}

.mdW64 {
  width: 16rem;
}

.gap2 {
  gap: 0.5rem;
}

.px4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.smPx6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.fontMedium {
  font-weight: 500;
}

.fontBold {
  font-weight: bold;
}

/* Additional styles */
.gradeContainer {
  position: relative;
}

.weekNumber {
  position: absolute;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  color: #2b2d42;
  font-weight: bold;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  margin-left: 33px;
}
