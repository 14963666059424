@keyframes float {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  
  .animated-float {
    animation: float 3s ease-in-out infinite;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .animated-float {
      animation: none;
    }
  }