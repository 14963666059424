.calendar-container {
    padding: 20px; /* Takvim bileşenine padding ekledik */
    border-radius: 15px; /* Takvim bileşeninin köşelerini yuvarladık */
    background-color: transparent; /* Arka planı kaldırdık */
  }
  
  .custom-calendar {
    background-color: #301934; /* Takvim arka planı */
    color: #FB8500; /* Yazı rengi */
    border-radius: 15px; /* Takvim köşelerini yuvarladık */
    padding: 10px; /* İçerik için ek padding */
  }
  
  .custom-calendar .rbc-toolbar {
    background-color: #301934;
    color: #FB8500;
  }
  
  .custom-calendar .rbc-event {
    background-color: #FB8500;
    color: white;
    border-radius: 5px;
    border: none;
  }
  
  .custom-calendar .rbc-month-view, 
  .custom-calendar .rbc-time-view {
    border: 1px solid white;
    padding: 10px; /* İçerik için ek padding */
  }
  
  .custom-calendar .rbc-header {
    background-color: #301934;
    color: #FB8500;
  }
  
  .custom-calendar .rbc-date-cell {
    color: #FB8500;
  }
  .custom-calendar .rbc-toolbar button {
    color: #FB8500; /* Varsayılan olarak buton yazı rengi turuncu */
    background-color: transparent;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease; /* Geçiş efekti */
  }
  
  .custom-calendar .rbc-toolbar button:hover {
    background-color: #FB8500; /* Üzerine gelindiğinde arka plan turuncu olur */
    color: #391934; /* Üzerine gelindiğinde yazı rengi koyu mor olur */
  }
  
  .custom-calendar .rbc-toolbar button:active {
    background-color: #FB8500; /* Butona basıldığında arka plan turuncu olur */
    color: #391934; /* Butona basıldığında yazı rengi koyu mor olur */
  }
    
  .custom-calendar .rbc-off-range-bg {
    background-color: #512b58;
  }
  .custom-calendar .rbc-today {
  background-color: #fce0a2;
}