/* TopicDetail.css */
.topic-detail-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-family: poppins;
  }
  
  .topic-detail-container h2 {
    margin-bottom: 16px;
  }
  
  .topic-detail-container p {
    line-height: 1.6;
  }
  
  .question-section {
    margin-top: 24px;
  }
  
  .options {
    margin-top: 16px;
  }
  
  .option {
    margin-bottom: 12px;
  }
  
  .submit-button {
    margin-top: 16px;
    padding: 8px 16px;
    background-color: #fb8500;
    color: white;
    border: none;
    border-radius: 24px;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
  }
  
  .submit-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .submit-button:hover:enabled {
    background-color: #ff9800;
  }
  
  .result {
    margin-top: 16px;
    padding: 8px 16px;
    border-radius: 8px;
    font-weight: bold;
  }
  
  .correct {
    color: green;
  }
  
  .incorrect {
    color: red;
  }
  .code-block {
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    margin: 10px 0;
    font-family: 'Courier New', Courier, monospace;
    font-size: 14px;
    overflow-x: auto;
  }
  