.admin-panel {
  display: flex;
  font-family: 'Arial', sans-serif;
  height: 100vh;
}

.admin-sidebar {
  width: 250px;
  background-color: #301934;
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.admin-header {
  text-align: center;
  margin-bottom: 30px;
}

.admin-header h1 {
  font-size: 24px;
  margin: 0;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.admin-navbar {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.admin-navbar button {
  background-color: #fb8500;
  color: white;
  border: none;
  padding: 12px 20px;
  margin: 5px 0;
  border-radius: 4px;
  cursor: pointer;
  text-align: left;
  transition: background-color 0.3s, transform 0.2s;
}

.admin-navbar button:hover {
  background-color: #f48c06;
  transform: translateX(5px);
}

.admin-content {
  flex-grow: 1;
  padding: 30px;
  overflow-y: auto;
  background-color: #f4f6f8;
}

.admin-content > div {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.course-selection {
  margin-bottom: 20px;
}

.course-selection label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.course-selection input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

/* Responsive tasarım için */
@media (max-width: 768px) {
  .admin-panel {
    flex-direction: column;
  }

  .admin-sidebar {
    width: 100%;
    height: auto;
  }

  .admin-navbar {
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }

  .admin-navbar button {
    margin: 5px;
  }
}