.exams-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  font-family: 'Poppins', sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* ExamPage.css */

.exam-page-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.exam-page-container h2 {
  margin-bottom: 10px;
}

.exam-page-container h3 {
  margin-bottom: 20px;
  color: #1890ff;
}

.exam-page-container p {
  font-size: 1.1em;
  color: #555;
}


.error-message {
  text-align: center;
  padding: 24px;
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
  border-radius: 8px;
  margin: 24px 0;
  width: 100%;
}

.error-message p {
  color: #ff4d4f;
  margin-bottom: 16px;
}

.retry-button {
  background-color: #ff4d4f;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.retry-button:hover {
  background-color: #ff7875;
}

.no-results {
  text-align: center;
  padding: 48px;
  background-color: #fafafa;
  border-radius: 8px;
  color: #666;
  width: 100%;
}

/* Diğer stiller aynı kalacak */
.exams-title {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 24px;
  color: #333333;
}

.exams-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  margin-bottom: 24px;
}

.exam-item {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 12px;
  background-color: #f9f9f9;
  transition: all 0.3s ease;
}

.exam-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.exam-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.exam-name {
  font-size: 20px;
  font-weight: 600;
  color: #333333;
  margin: 0;
}

.exam-date {
  font-size: 14px;
  color: #666666;
}

.exam-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.exam-stats {
  display: flex;
  gap: 24px;
}

.stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stat-label {
  font-size: 14px;
  color: #666666;
  margin-bottom: 4px;
}

.stat-value {
  font-size: 24px;
  font-weight: 600;
  padding: 4px 12px;
  border-radius: 6px;
}

.stat-value.correct {
  color: #52c41a;
  background-color: rgba(82, 196, 26, 0.1);
}

.stat-value.wrong {
  color: #ff4d4f;
  background-color: rgba(255, 77, 79, 0.1);
}

.stat-value.empty {
  color: #faad14;
  background-color: rgba(250, 173, 20, 0.1);
}

.grade-circle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  color: white;
}

.grade-value {
  font-size: 24px;
  font-weight: bold;
  line-height: 1;
}

.grade-label {
  font-size: 12px;
  margin-top: 4px;
}

.loading-skeleton {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.skeleton-item {
  height: 120px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
  border-radius: 12px;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.legend {
  width: 100%;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 12px;
}

.legend-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 16px;
  color: #333333;
}

.legend-items {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 12px;
}

.legend-item {
  display: flex;
  align-items: center;
  padding: 8px;
  background-color: white;
  border-radius: 6px;
}

.legend-color {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 12px;
}

.legend-label {
  font-size: 14px;
  font-weight: 500;
  color: #444444;
}
.exams-container {
  /* Mevcut stiller korunacak */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  font-family: 'Poppins', sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* ... diğer mevcut stiller ... */

.exam-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
}

.start-exam-button {
  background-color: #1890ff;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(24, 144, 255, 0.2);
  min-width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.start-exam-button:hover {
  background-color: #096dd9;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(24, 144, 255, 0.3);
}

.start-exam-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(24, 144, 255, 0.2);
}

.start-exam-button:disabled {
  background-color: #d9d9d9;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

@media (max-width: 768px) {
  .exam-details {
    flex-direction: column;
    align-items: stretch;
  }
  
  .start-exam-button {
    width: 100%;
    margin-top: 16px;
  }
  
  .exam-stats {
    justify-content: center;
  }
}