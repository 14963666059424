@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

.trial_popup_overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  z-index: 9999;
  font-family: 'Poppins', sans-serif;
}

.trial_popup_container {
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  width: 100%;
  max-width: 56rem;
  position: relative;
  overflow: hidden;
  max-height: 90vh; /* Ekranın 90%'ını kaplasın */
  overflow-y: auto; /* İçerik fazlaysa scroll olsun */
}

.trial_popup_close_btn {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  background-color: white;
  border-radius: 9999px;
  padding: 0.25rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  z-index: 10;
  transition: background-color 0.2s;
  cursor: pointer;
  border: none;
}

.trial_popup_content_wrapper {
  display: flex;
  flex-direction: column;
}

.trial_popup_image_section {
  width: 100%;
  position: relative;
  height: 200px; /* Mobilde daha küçük */
}

.trial_popup_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.trial_popup_free_tag {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  background-color: #FB8500;
  color: white;
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.75rem;
  font-weight: 500;
}

.trial_popup_content_section {
  padding: 1rem; /* Mobilde daha az padding */
  width: 100%;
  background: linear-gradient(to bottom right, white, #fff7ed);
}

.trial_popup_title {
  font-size: 1.5rem; /* Mobilde daha küçük başlık */
  font-weight: 700;
  color: #FB8500;
  margin-bottom: 0.5rem;
}

.trial_popup_subtitle {
  color: #4b5563;
  font-size: 1rem; /* Mobilde daha küçük alt başlık */
  margin-bottom: 1rem;
}

.trial_popup_features_list {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-bottom: 1rem;
}

.trial_popup_feature_card {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  background-color: white;
  padding: 0.75rem;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.trial_popup_feature_icon {
  color: #FB8500;
  min-width: 20px; /* İkon boyutu sabit kalsın */
}

.trial_popup_feature_title {
  font-weight: 600;
  font-size: 0.875rem; /* Mobilde daha küçük başlıklar */
}

.trial_popup_feature_desc {
  font-size: 0.75rem;
  color: #6b7280;
}

.trial_popup_button_group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
}

.trial_popup_primary_btn {
  width: 100%;
  background-color: #FB8500;
  color: white;
  font-weight: 700;
  padding: 0.75rem 1rem; /* Mobilde daha az padding */
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transition: all 0.2s;
  border: none;
  cursor: pointer;
  font-size: 0.875rem; /* Mobilde daha küçük buton yazısı */
}

.trial_popup_secondary_btn {
  width: 100%;
  background-color: transparent;
  color: #4b5563;
  font-weight: 500;
  padding: 0.75rem 1rem;
  border-radius: 0.75rem;
  border: 1px solid #e5e7eb;
  transition: background-color 0.2s;
  cursor: pointer;
  font-size: 0.875rem;
}

.trial_popup_disclaimer {
  font-size: 0.75rem;
  color: #6b7280;
  text-align: center;
  margin-top: 0.75rem;
}

/* Tablet ve Masaüstü için Medya Sorguları */
@media (min-width: 768px) {
  .trial_popup_overlay {
    padding: 1rem;
  }

  .trial_popup_container {
    max-height: 85vh;
  }

  .trial_popup_content_wrapper {
    flex-direction: row;
  }

  .trial_popup_image_section {
    width: 50%;
    height: auto;
    min-height: 400px;
  }

  .trial_popup_content_section {
    width: 50%;
    padding: 2rem;
  }

  .trial_popup_title {
    font-size: 1.875rem;
  }

  .trial_popup_subtitle {
    font-size: 1.125rem;
  }

  .trial_popup_feature_title {
    font-size: 1rem;
  }

  .trial_popup_feature_desc {
    font-size: 0.875rem;
  }

  .trial_popup_primary_btn,
  .trial_popup_secondary_btn {
    padding: 1rem 1.5rem;
    font-size: 1rem;
  }

  .trial_popup_close_btn {
    right: 1rem;
    top: 1rem;
  }
}

/* Touch cihazlar için scrollbar stilini gizle */
@media (hover: none) {
  .trial_popup_container {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  
  .trial_popup_container::-webkit-scrollbar {
    display: none;
  }
}