.course-enrollment-container {
  font-family: 'Poppins', sans-serif;
  max-width: 1400px;
  margin: 0 auto;
  padding: 60px 20px;
  background-color: #f8f9fa;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  margin-bottom: 50px;
}

.course-enrollment-container h2 {
  text-align: center;
  color: #301934;
  font-size: 2.5rem;
  margin-bottom: 50px;
  position: relative;
  font-weight: 700;
}

.course-enrollment-container h2::after {
  content: '';
  display: block;
  width: 50px;
  height: 4px;
  background-color: #fb8500;
  margin: 15px auto 0;
  border-radius: 2px;
}

.enrollment-process {
  position: relative;
  padding-left: 30px;
}

.step {
  display: flex;
  margin-bottom: 40px;
  position: relative;
}

.step-connector {
  position: absolute;
  left: -30px;
  top: -20px;
  bottom: -20px;
  width: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.connector-line-top,
.connector-line-bottom {
  flex-grow: 1;
  width: 2px;
  background-color: #fb8500;
}

.connector-dot {
  width: 16px;
  height: 16px;
  background-color: #fb8500;
  border-radius: 50%;
  z-index: 2;
  margin: 10px 0;
}

.first-step .connector-line-top,
.last-step .connector-line-bottom {
  display: none;
}

.step-content {
  display: flex;
  align-items: flex-start;
}

.step-icon {
  background-color: #fb8500;
  color: white;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  margin-right: 20px;
  flex-shrink: 0;
}

.step-text h3 {
  color: #301934;
  margin-bottom: 5px;
  font-size: 1.2rem;
}

.step-text p {
  color: #4B5563;
  font-size: 0.95rem;
  line-height: 1.5;
}

.enrollment-info {
  margin-top: 50px;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

.enrollment-info h3 {
  color: #301934;
  margin-bottom: 20px;
  font-size: 1.5rem;
}

.enrollment-info ul {
  padding-left: 20px;
  list-style-type: none;
}

.enrollment-info li {
  color: #4B5563;
  margin-bottom: 15px;
  position: relative;
  padding-left: 25px;
}

.enrollment-info li::before {
  content: '✓';
  position: absolute;
  left: 0;
  color: #fb8500;
  font-weight: bold;
}

@media (max-width: 600px) {
  .course-enrollment-container {
    padding: 40px 15px;
  }

  .course-enrollment-container h2 {
    font-size: 2rem;
  }

  .step-content {
    flex-direction: column;
  }

  .step-icon {
    margin-bottom: 15px;
  }

  .enrollment-info {
    padding: 20px;
  }
}