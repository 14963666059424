/* Experience Component CSS */

/* General styles */
.exp{
  font-size: x-large;
  width: 100%;
}
.experience-bg-white {
    background-color: #ffffff;
  }
  
  .experience-justify-center {
    justify-content: center;
  }
  
  .experience-items-center {
    align-items: center;
  }
  
  .experience-mt-14 {
    margin-top: 3.5rem;
  }
  
  .experience-flex {
    display: flex;
  }
  
  .experience-flex-col {
    flex-direction: column;
  }
  
  .experience-gap-4 {
    gap: 1rem;
  }
  
  .experience-gap-3 {
    gap: 0.75rem;
  }
  
  .experience-rounded-lg {
    border-radius: 0.5rem;
  }
  
  .experience-rounded-xl {
    border-radius: 0.75rem;
  }
  
  /* Text styles */
  .experience-text-center {
    text-align: center;
  }
  
  .experience-text-primary {
    color: #301934;
  }
  
  .experience-font-poppins {
    font-family: 'Poppins', sans-serif;
  }
  
  .experience-font-medium {
    font-weight: 500;
  }
  
  .experience-font-bold {
    font-weight: 700;
  }
  
  .experience-font-semibold {
    font-weight: 600;
  }
  
  .experience-text-xl {
    font-size: 1.25rem;
  }
  
  .experience-text-lg {
    font-size: 1.125rem;
  }
  
  .experience-text-base {
    font-size: 1rem;
  }
  
  .experience-text-5xl {
    font-size: 3rem;
  }
  
  .experience-text-accent {
    color: #FB8500;
  }
  
  .experience-text-white {
    color: #FFFFFF;
  }
  
  /* Padding and margin */
  .experience-px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  
  .experience-py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  
  /* Background color */
  .experience-bg-orange-logo {
    background-color: #FB8500;
  }
  
  /* Mobile specific styles */
  @media (max-width: 768px) {
    .experience-flex {
      flex-direction: column;
    }
  
    .experience-mt-14 {
      margin-top: 1rem;
    }
  }

  @media (max-width: 468px) {
    .exp{
      font-size: large;
    }
    
  }
  