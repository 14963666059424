.curriculum-management {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.curriculum-management h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tabs button {
  padding: 10px 20px;
  margin: 0 5px;
  background-color: #eee;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.tabs button.active {
  background-color: #5cb85c;
  color: #fff;
}

.tab-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
}

.new-lesson-form,
.new-activity-form {
  margin-bottom: 30px;
}

.new-lesson-form h3,
.new-activity-form h3 {
  margin-bottom: 15px;
  color: #333;
}

.new-lesson-form input[type="text"],
.new-lesson-form input[type="number"],
.new-lesson-form textarea,
.new-activity-form input[type="text"],
.new-activity-form input[type="number"] {
  width: calc(100% - 10px);
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.new-lesson-form textarea {
  height: 80px;
  resize: none;
}

.new-lesson-form label,
.new-activity-form label {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  color: #555;
}

.new-lesson-form label input,
.new-activity-form label input {
  margin-right: 10px;
}

.new-lesson-form button,
.new-activity-form button {
  padding: 10px 15px;
  background-color: #5cb85c;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.new-lesson-form button:hover,
.new-activity-form button:hover {
  background-color: #4cae4c;
}

.lessons-list,
.activities-list {
  margin-top: 20px;
}

.lessons-list h3,
.activities-list h3 {
  margin-bottom: 15px;
  color: #333;
}

.lesson-item {
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
}

.lesson-item:last-child {
  border-bottom: none;
}

.lesson-details {
  margin-bottom: 10px;
}

.lesson-details p {
  margin: 0;
  color: #555;
}

.lesson-actions,
.activity-actions {
  display: flex;
  gap: 10px;
}

.lesson-actions button,
.activity-actions button {
  padding: 5px 10px;
  background-color: #337ab7;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
}

.lesson-actions button:last-child,
.activity-actions button:last-child {
  background-color: #d9534f;
}

.lesson-actions button:hover,
.activity-actions button:hover {
  opacity: 0.9;
}

.activities-table {
  width: 100%;
  border-collapse: collapse;
}

.activities-table th,
.activities-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.activities-table th {
  background-color: #f2f2f2;
}

.activities-table td button {
  margin-right: 5px;
}

/* Popup Stilleri */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.popup-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
}

.popup-content p {
  margin: 0;
  font-size: 16px;
  color: #333;
}
