/* InteractiveKidsCodingCourses.css */

.interactive-kids-coding-container {
  background-color: #ffffff;
  padding: 4rem 2rem;
  font-family: 'Poppins', sans-serif;
}

.interactive-kids-coding-title {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 700;
  color: #301934;
  margin-bottom: 2rem;
}

.interactive-kids-coding-content {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.interactive-kids-coding-course-section {
  flex: 1;
}

.interactive-kids-coding-course-tabs {
  display: flex;
  margin-bottom: 1rem;
}

.interactive-kids-coding-course-tab {
  padding: 0.5rem 1rem;
  background-color: #f3f4f6;
  border: none;
  border-radius: 20px;
  margin-right: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.interactive-kids-coding-course-tab-active {
  background-color: #FB8500;
  color: white;
}

.interactive-kids-coding-course-card {
  background-color: #FFFFFF;
  border-radius: 20px;
  padding: 2rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
  border: 3px solid #EF4444;
}

.interactive-kids-coding-course-icon {
  font-size: 3rem;
  color: #FB8500;
  margin-bottom: 1rem;
  text-align: center;
}

.interactive-kids-coding-course-title {
  font-size: 1.5rem;
  font-weight: 700;
  color: #301934;
  margin-bottom: 1rem;
}

.interactive-kids-coding-course-description {
  font-size: 1rem;
  color: #4B5563;
  margin-bottom: 1.5rem;
}

.interactive-kids-coding-course-features {
  list-style-type: none;
  padding: 0;
  margin-bottom: 1.5rem;
}

.interactive-kids-coding-course-feature-item {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  color: #4B5563;
}

.interactive-kids-coding-course-feature-item::before {
  content: "•";
  color: #10B981;
  font-weight: bold;
  margin-right: 0.5rem;
}

.interactive-kids-coding-course-certificate {
  display: flex;
  align-items: center;
  color: #301934;
  font-weight: 600;
}

.interactive-kids-coding-course-certificate svg {
  margin-right: 0.5rem;
  color: #10B981;
}

.interactive-kids-coding-promo-section {
  flex: 1;
  background-color: #f3f4f6;
  padding: 2rem;
  border-radius: 20px;
}

.interactive-kids-coding-promo-title {
  font-size: 1.8rem;
  font-weight: 700;
  color: #301934;
  margin-bottom: 1rem;
}

.interactive-kids-coding-promo-description {
  font-size: 1.1rem;
  color: #4B5563;
  margin-bottom: 1.5rem;
}

.interactive-kids-coding-skills-title {
  font-size: 1.3rem;
  font-weight: 600;
  color: #301934;
  margin-bottom: 1rem;
}

.interactive-kids-coding-skills-list {
  list-style-type: none;
  padding: 0;
  margin-bottom: 2rem;
}

.interactive-kids-coding-skill-item {
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
  color: #4B5563;
}

.interactive-kids-coding-skill-icon {
  color: #FB8500;
  font-size: 1.25rem;
  margin-right: 0.75rem;
}

/* Sözleşme Bölümü CSS Kodları */

.interactive-kids-coding-terms-conditions {
  margin-bottom: 1rem;
}

.interactive-kids-coding-terms-item {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.interactive-kids-coding-terms-checkbox {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid #FB8500;
  border-radius: 3px;
  margin-right: 10px;
  cursor: pointer;
  position: relative;
  flex-shrink: 0;
}

.interactive-kids-coding-terms-checkbox:checked {
  background-color: #FB8500;
}

.interactive-kids-coding-terms-checkbox:checked::after {
  content: '\2714';
  font-size: 14px;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.interactive-kids-coding-terms-label {
  font-size: 14px;
  line-height: 1.4;
  color: #4B5563;
  flex: 1;
  white-space: normal;
}

.interactive-kids-coding-terms-link {
  color: #FB8500;
  cursor: pointer;
  text-decoration: underline;
}

.interactive-kids-coding-error-message {
  color: #EF4444;
  font-size: 14px;
  margin-bottom: 1rem;
}

/* Buton CSS Kodları */

.interactive-kids-coding-free-trial-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem;
  background-color: #FB8500;
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.interactive-kids-coding-free-trial-button:hover {
  background-color: #F97316;
}

.interactive-kids-coding-free-trial-button-disabled {
  background-color: #D1D5DB;
  cursor: not-allowed;
}

.interactive-kids-coding-button-icon {
  margin-right: 0.5rem;
}

/* Mobil Uyumluluk */

@media (max-width: 768px) {
  .interactive-kids-coding-content {
    flex-direction: column;
  }

  .interactive-kids-coding-course-section,
  .interactive-kids-coding-promo-section {
    width: 100%;
  }

  .interactive-kids-coding-course-tabs {
    flex-wrap: wrap;
  }

  .interactive-kids-coding-course-tab {
    margin-bottom: 0.5rem;
  }

  .interactive-kids-coding-title {
    font-size: 2rem;
  }

  .interactive-kids-coding-promo-title {
    font-size: 1.5rem;
  }

  .interactive-kids-coding-promo-description {
    font-size: 1rem;
  }

  .interactive-kids-coding-terms-label {
    font-size: 13px;
    line-height: 1.3;
  }
}
