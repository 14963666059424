.pci-course-container {
  min-height: 100vh;
  padding: 1rem;
  font-family: 'Poppins', sans-serif;
}

.pci-wrapper {
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;
}

.pci-course-card {
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.15);
  padding: 1.25rem;
  margin: 1rem;
}

@media (min-width: 768px) {
  .pci-course-container {
    padding: 3rem;
  }
  
  .pci-course-card {
    padding: 2rem;
    margin: 0;
  }
}

.pci-course-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

@media (min-width: 1024px) {
  .pci-course-content {
    flex-direction: row;
    gap: 3rem;
  }
}

.pci-content-left {
  flex: 1;
}

/* Badge Styles */
.pci-badges-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  margin-bottom: 1.5rem;
}

.pci-animated-badge {
  position: relative;
}

.pci-animated-badge::before {
  content: '';
  position: absolute;
  inset: -1px;
  border-radius: 9999px;
  background: linear-gradient(to right, var(--gradient-from), var(--gradient-to));
  opacity: 0.75;
  filter: blur(4px);
  transition: opacity 0.5s;
}

.pci-badge-content {
  position: relative;
  padding: 0.5rem 1.25rem;
  border-radius: 9999px;
  overflow: hidden;
}

.pci-badge-purple {
  --gradient-from: rgb(139 92 246);
  --gradient-to: rgb(168 85 247);
}

.pci-badge-purple .pci-badge-content {
  background-color: rgb(245 243 255);
  border: 1px solid rgb(224 231 255);
}

.pci-badge-green {
  --gradient-from: rgb(34 197 94);
  --gradient-to: rgb(16 185 129);
}

.pci-badge-green .pci-badge-content {
  background-color: rgb(240 253 244);
  border: 1px solid rgb(220 252 231);
}

/* Animated Elements */
.pci-animated-circle-1 {
  position: absolute;
  width: 1rem;
  height: 1rem;
  background-color: currentColor;
  border-radius: 9999px;
  opacity: 0.2;
  top: -0.5rem;
  right: -0.5rem;
  animation: pci-ping 2s cubic-bezier(0, 0, 0.2, 1) infinite;
}

.pci-animated-circle-2 {
  position: absolute;
  width: 0.75rem;
  height: 0.75rem;
  background-color: currentColor;
  border-radius: 9999px;
  opacity: 0.2;
  bottom: -0.25rem;
  left: -0.25rem;
  animation: pci-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.pci-animated-dot {
  position: absolute;
  width: 0.375rem;
  height: 0.375rem;
  background-color: currentColor;
  border-radius: 9999px;
  opacity: 0.2;
  top: 50%;
  right: 1rem;
  animation: pci-bounce 1s infinite;
}

.pci-badge-text {
  position: relative;
  z-index: 10;
  font-size: 0.875rem;
  font-weight: 600;
}

@media (min-width: 768px) {
  .pci-badge-text {
    font-size: 1rem;
  }
}

/* Course Info */
.pci-course-title {
  font-size: 2rem;
  font-weight: 700;
  color: rgb(17 24 39);
  margin-bottom: 1.5rem;
}

@media (min-width: 768px) {
  .pci-course-title {
    font-size: 3rem;
    margin-bottom: 2rem;
  }
}

.pci-course-description {
  font-size: 1.125rem;
  color: rgb(55 65 81);
  line-height: 1.75;
  margin-bottom: 1.5rem;
}

@media (min-width: 768px) {
  .pci-course-description {
    font-size: 1.25rem;
    margin-bottom: 2rem;
  }
}

.pci-students-info {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.pci-students-info .pci-icon {
  width: 1.25rem;
  height: 1.25rem;
  color: rgb(124 58 237);
  margin-right: 0.75rem;
}

/* Features */
.pci-features-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

@media (min-width: 640px) {
  .pci-features-grid {
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
  }
}

.pci-feature-card {
  display: flex;
  align-items: center;
  padding: 1.25rem;
  background-color: rgb(249 250 251);
  border-radius: 0.75rem;
}

.pci-feature-icon {
  width: 1.75rem;
  height: 1.75rem;
  margin-right: 0.875rem;
}

.pci-feature-icon.pci-yellow {
  color: #ffe054;
}

.pci-feature-icon.pci-purple {
  color:  #fb8500;
}

.pci-feature-title {
  font-weight: 600;
  font-size: 1rem;
}

.pci-feature-subtitle {
  font-size: 0.875rem;
  color: rgb(75 85 99);
}

/* Pricing Section */
.pci-pricing-container {
  width: 100%;
}

@media (min-width: 1024px) {
  .pci-pricing-container {
    width: 420px;
  }
}

.pci-pricing-card {
  background-color: rgb(249 250 251);
  border-radius: 0.75rem;
  padding: 1.5rem;
}

@media (min-width: 768px) {
  .pci-pricing-card {
    padding: 2rem;
    position: sticky;
    top: 1.5rem;
  }
}

.pci-pricing-header h2 {
  font-size: 1.5rem;
  font-weight: 700;
  color: rgb(17 24 39);
  margin-bottom: 1.25rem;
}

@media (min-width: 768px) {
  .pci-pricing-header h2 {
    font-size: 1.875rem;
    margin-bottom: 1.5rem;
  }
}

.pci-pricing-content {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.pci-price-row, .pci-detail-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1.25rem;
  border-bottom: 1px solid rgb(229 231 235);
}

.pci-price-row span:first-child,
.pci-detail-row span:first-child {
  font-size: 1.125rem;
  color: rgb(75 85 99);
}

.pci-price {
  font-size: 1.5rem;
  font-weight: 700;
  color: rgb(17 24 39);
}

@media (min-width: 768px) {
  .pci-price {
    font-size: 1.875rem;
  }
}

.pci-time-info {
  display: flex;
  align-items: center;
}

.pci-time-info .pci-icon {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.5rem;
  color: rgb(107 114 128);
}

/* Buttons */
.pci-primary-button {
  width: 100%;
  background-color: #fb8500;
  color: white;
  padding: 0.875rem 1.25rem;
  border-radius: 0.75rem;
  font-size: 1.125rem;
  font-weight: 600;
  transition: background-color 0.2s;
}

.pci-primary-button:hover {
  background-color: #fb8600e3;
}

.pci-secondary-button {
  width: 100%;
  background-color: transparent;
  border: 2px solid rgb(209 213 219);
  color: rgb(55 65 81);
  padding: 0.875rem 1.25rem;
  border-radius: 0.75rem;
  font-size: 1.125rem;
  font-weight: 600;
  transition: background-color 0.2s;
}

.pci-secondary-button:hover {
  background-color: rgb(243 244 246);
}

@media (min-width: 768px) {
  .pci-primary-button,
  .pci-secondary-button {
    padding: 1rem 1.5rem;
    font-size: 1.25rem;
  }
}

/* Animations */
@keyframes pci-ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes pci-pulse {
  50% {
    opacity: .5;
  }
}

@keyframes pci-bounce {
  0%, 100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

/* Mobile Optimizations */
@media (max-width: 639px) {
  .pci-course-card {
    margin: 0.5rem;
    padding: 1rem;
  }
  
  .pci-badges-container {
    gap: 0.5rem;
    margin-bottom: 1rem;
  }
  
  .pci-badge-content {
    padding: 0.375rem 1rem;
  }
  
  .pci-feature-card {
    padding: 1rem;
  }
  
  .pci-feature-icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.75rem;
  }
  
  .pci-pricing-card {
    padding: 1.25rem;
  }
  
  .pci-price-row, 
  .pci-detail-row {
    padding-bottom: 1rem;
  }
  
  .pci-course-description {
    font-size: 1rem;
    line-height: 1.625;
  }
}

/* Tablet Optimizations */
@media (min-width: 640px) and (max-width: 1023px) {
  .pci-course-content {
    gap: 2rem;
  }
  
  .pci-pricing-container {
    max-width: 100%;
  }
  
  .pci-pricing-card {
    position: relative;
    top: 0;
  }
}

/* Print Styles */
@media print {
  .pci-course-container {
    background-color: white;
    padding: 0;
  }
  
  .pci-course-card {
    box-shadow: none;
  }
  
  .pci-animated-circle-1,
  .pci-animated-circle-2,
  .pci-animated-dot {
    display: none;
  }
  
  .pci-primary-button,
  .pci-secondary-button {
    display: none;
  }
}

/* Accessibility */
@media (prefers-reduced-motion: reduce) {
  .pci-animated-circle-1,
  .pci-animated-circle-2,
  .pci-animated-dot {
    animation: none;
  }
  
  .pci-primary-button,
  .pci-secondary-button {
    transition: none;
  }
}

/* High Contrast Mode */
@media (prefers-contrast: high) {
  .pci-badge-purple .pci-badge-content,
  .pci-badge-green .pci-badge-content {
    border-width: 2px;
  }
  
  .pci-feature-card {
    border: 1px solid currentColor;
  }
  
  .pci-pricing-card {
    border: 2px solid rgb(17 24 39);
  }
}

/* Mevcut CSS'in sonuna eklenecek yeni stiller */

.pci-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 1rem;
}

.pci-popup {
  background-color: white;
  border-radius: 1rem;
  padding: 2rem;
  position: relative;
  width: 100%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  animation: pci-popup-show 0.3s ease-out;
}

@keyframes pci-popup-show {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.pci-popup-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  padding: 0.5rem;
  transition: color 0.2s;
}

.pci-popup-close:hover {
  color: #000;
}

.pci-popup-title {
  font-size: 1.5rem;
  font-weight: 700;
  color: #2B2D42;
  margin-bottom: 1.5rem;
  padding-right: 2rem;
}

.pci-popup-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.pci-popup-option {
  padding: 1.5rem;
  background-color: #f9fafb;
  border-radius: 0.75rem;
  transition: transform 0.2s;
}

.pci-popup-option:hover {
  transform: translateY(-2px);
}

.pci-popup-option h3 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #2B2D42;
  margin-bottom: 0.75rem;
}

.pci-popup-option p {
  color: #6b7280;
  margin-bottom: 1.25rem;
  line-height: 1.5;
}

.pci-popup-button {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  font-weight: 600;
  text-align: center;
  transition: all 0.2s;
  width: 100%;
}

.pci-popup-button.pci-whatsapp {
  background-color: #25D366;
  color: white;
}

.pci-popup-button.pci-whatsapp:hover {
  background-color: #22c35e;
}

.pci-popup-button.pci-form {
  background-color: #fb8500;
  color: white;
}

.pci-popup-button.pci-form:hover {
  background-color: #fb8600e3;
}

@media (min-width: 640px) {
  .pci-popup {
    padding: 2.5rem;
  }

  .pci-popup-title {
    font-size: 1.75rem;
  }

  .pci-popup-option {
    padding: 2rem;
  }
}

@media (max-width: 639px) {
  .pci-popup {
    padding: 1.5rem;
  }

  .pci-popup-content {
    gap: 1rem;
  }

  .pci-popup-option {
    padding: 1.25rem;
  }
}