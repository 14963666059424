.avatar-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 50;
  }
  
  .avatar-popup {
    background: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
  }
  
  .avatar-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* Her satırda 5 avatar olacak şekilde düzenleme */
    gap: 10px;
    justify-content: center;
    margin: 20px 0;
  }
  
  .avatar-image {
    width: 80px;
    height: 80px;
    cursor: pointer;
    border-radius: 50%;
    transition: transform 0.3s;
  }
  
  .avatar-image:hover {
    transform: scale(1.1);
  }
  
  .close-button {
    padding: 10px 20px;
    background: #fb8500;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  