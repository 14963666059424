/* General styling for the chatbot */
df-messenger {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  width: 350px; /* Wider default size */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Soft shadow for a modern look */
  border-radius: 20px; /* Rounded corners */
}

/* Adjustments for mobile devices */
@media only screen and (max-width: 600px) {
df-messenger {
  bottom: 80px; /* More space from the bottom on mobile */
  right: 10px;
  width: 90%; /* Full width on mobile */
  max-width: 400px;
  border-radius: 15px;
}
}

/* Chat bubble design */
df-messenger .df-messenger-bot-bubble {
  background-color: #0b57d0;
  color: white;
}

df-messenger .df-messenger-user-bubble {
  background-color: #e1e3e1;
  color: #1f1f1f;
}

/* Title bar design */
df-messenger .df-titlebar {
  background-color: #0b57d0;
  color: white;
  padding: 10px 15px;
  border-radius: 20px 20px 0 0;
}

df-messenger .df-titlebar-title {
  font-family: 'Google Sans', 'Helvetica Neue', sans-serif;
  font-size: 18px;
  font-weight: bold;
}

/* Input box styling */
df-messenger .df-input-box {
  border-top: 1px solid #e0e0e0;
  padding: 10px;
}

df-messenger .df-input-box input {
  font-family: 'Google Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
}

df-messenger .df-input-box button {
  background-color: #0b57d0;
  color: white;
  border-radius: 50px;
}

/* Scroll button */
df-messenger .df-scroll-button {
  background-color: #0b57d0;
  color: white;
}
