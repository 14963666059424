.week-container {
  padding: 20px;
  font-family: poppins;
  background-color: white;
  border-radius: 20px;
  padding: 40px;
  margin: 40px;
}
.week-title{
  color: #fb8500;
  font-weight: 500;
  font-size: 1.5em;
  text-align: center;
  margin-left: -100px;

}
.description {
}

.lesson {

}

.lesson-content {

}

.quiz {
  margin-bottom: 20px;
  background-color:#fb8500;
  padding: 30px;
  border-radius: 20px;
}

.options {
  display: flex;
  flex-direction: column;

}

.option {
  margin-bottom: 10px;
  border: 1px solid #fce0a2;
  background-color: white;
}

.submit-button {
  padding: 10px 20px;
  background-color: orange;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.result {
  margin-top: 20px;
  font-weight: bold;
}

.title {
  color: #fb8500;
  font-size: 1.2em;
  justify-content: left;
  font-weight: 500;
  margin-left: -80px;
  margin-bottom: -50px;
  margin-top: -20px;
}

.code {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  font-family: 'Courier New', Courier, monospace;
  white-space: pre-wrap;
  margin-top: 10px;
  margin-bottom: 10px;
}
