.attendance-card {
    display: flex;
    background-color: white;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .name-label {
    color: #FB8500;
    font-weight: bold;
    font-size: 18px;
    margin-left: 50px;
    display: flex;
    align-items: center;
    padding-right: 0px;
    border-right: 2px solid #ddd;
    min-width: 180px;
  }
  
  .weeks-container {
    display: flex;
    flex-direction: column;
  }
  
  .module-row {
    display: flex;
  }
  
  .module-label {
    width: 180px; /* 4 kutucuk x 40px */
    text-align: center;
    font-weight: bold;
    color: #FB8500;
    margin: 10px 10px;
    border: solid #FB8500 1px;
    border-radius: 10px;
    
    background-color: white;
  }
  
  .week-row {
    display: flex;
  }
  
  .week2-container {
    position: relative;
    margin: 5px;
  }
  
  .week-box {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: grey;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .week-box.yellow {
    background-color: #FFE054;
  }
  
  .week-box.red {
    background-color: #FF7283;
  }
  
  .week-box.green {
    background-color: #58DD91;
  }
  
  .week-number {
    font-size: 12px;
  }
  
  .color-options {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50px;
    left: 0;
    background: white;
    border: 1px solid #ccc;
    padding: 5px;
    z-index: 10;
  }
  
  .color-options button {
    margin: 2px 0;
    padding: 5px;
    border: none;
    cursor: pointer;
  }
  
  .color-options button:hover {
    background-color: #ddd;
  }
  