
.drag-drop-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .card {
    padding: 10px 20px;
    margin: 10px;
    background-color: #fce0a2;
    border: 1px solid #ccc;
    border-radius: 8px;
    cursor: grab;
    transition: background-color 0.3s ease;
    max-width: 200px;
    max-height: 100px;
    text-align: center;
  }
  
  .card.dragging {
    opacity: 0.5;
  }
  
  .slots-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    width: 100%;
  }
  
  .slot {
    padding: 20px;
    margin: 10px;
    background-color: rgba(252, 224, 162, 0.7);
    border: 1px dashed #ccc;
    border-radius: 8px;
    min-width: 200px;
    min-height: 300px;
    max-height: 400px; /* Yüksekliği ayarlayarak içerik sığmadığında scrollbar ekledik */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    transition: background-color 0.3s ease;
    overflow-y: auto; /* Scrollbar ekledik */
  }
  
  .slot:hover {
    background-color: #fb8500;
  }
  
  .slot-title {
    margin-bottom: 10px;
    font-weight: bold;
    color: #fb8500;
  }
  
  .slot .card {
    margin: 5px 0;
    cursor: default; /* Slot içindeki kartlar için cursor'u default yaptık */
  }
  