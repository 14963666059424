/* Selected Activity Styles */
.ubiAI-selected-activity {
    background: var(--ubiAI-card-bg);
    border: 1px solid var(--ubiAI-border);
    border-radius: 0.75rem;
    margin-bottom: 1rem;
    transition: all 0.2s ease;
  }
  
  .ubiAI-selected-activity-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.875rem 1rem;
    border-radius: 0.75rem;
    background: var(--ubiAI-hover);
  }
  
  .ubiAI-selected-activity-info {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    color: var(--ubiAI-text);
  }
  
  .ubiAI-selected-activity-info svg {
    color: var(--ubiAI-primary);
  }
  
  .ubiAI-selected-activity-info span {
    font-size: 0.875rem;
    font-weight: 500;
  }
  
  .ubiAI-remove-button {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-size: 0.75rem;
    color: #ef4444;
    background: rgba(239, 68, 68, 0.1);
    border: none;
    border-radius: 0.375rem;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .ubiAI-remove-button:hover {
    background: rgba(239, 68, 68, 0.15);
    transform: translateY(-1px);
  }
  
  .ubiAI-remove-button:active {
    transform: translateY(0);
  }
  
  /* Activity Badge */
  .ubiAI-activity-badge {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.25rem 0.75rem;
    background: var(--ubiAI-hover);
    border-radius: 1rem;
    font-size: 0.75rem;
    color: var(--ubiAI-primary);
  }
  
  .ubiAI-activity-badge svg {
    width: 14px;
    height: 14px;
  }
  
  /* Dark theme adjustments */
  .theme-dark .ubiAI-selected-activity {
    background: var(--ubiAI-card-bg-dark);
    border-color: var(--ubiAI-border-dark);
  }
  
  .theme-dark .ubiAI-selected-activity-header {
    background: rgba(251, 133, 0, 0.1);
  }
  
  .theme-dark .ubiAI-remove-button {
    background: rgba(239, 68, 68, 0.15);
  }
  
  .theme-dark .ubiAI-remove-button:hover {
    background: rgba(239, 68, 68, 0.25);
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .ubiAI-selected-activity-header {
      padding: 0.75rem;
    }
    
    .ubiAI-selected-activity-info span {
      font-size: 0.813rem;
    }
    
    .ubiAI-remove-button {
      padding: 0.25rem 0.5rem;
    }
  }
  
  /* Animation */
  .ubiAI-selected-activity {
    animation: ubiAI-slideIn 0.3s ease-out;
  }
  
  @keyframes ubiAI-slideIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }