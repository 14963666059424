.scores-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: 'Poppins', sans-serif;
  max-width: 1150px;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.scores-title {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 24px;
  color: #333333;
}

.scores-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin-bottom: 24px;
}

.score-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-radius: 8px;
  background-color: #f9f9f9;
  transition: all 0.3s ease;
}

.score-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.week-label {
  font-weight: 500;
  font-size: 24px;
  color: #444444;
}

.progress-circle {
  position: relative;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-circle::before {
  content: '';
  position: absolute;
  width: 56px;
  height: 56px;
  background: white;
  border-radius: 50%;
}

.progress-circle-value {
  position: relative;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}

.loading-skeleton {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.skeleton-item {
  height: 70px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
  border-radius: 8px;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.legend {
  width: 100%;
  padding: 16px;
  background-color: #f9f9f9;
  border-radius: 8px;
  margin-top: 24px;
}

.legend-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 12px;
  color: #333333;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.legend-color {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 12px;
}

.legend-label {
  font-size: 16px;
  font-weight: 500;
  color: #444444;
}